import React, {useState, useEffect} from 'react'
import { Form } from 'react-bootstrap'
import Swal from 'sweetalert2';
import { categories } from './data';
import Cookie from 'react-cookies';
const ViewStoreItem = () => {
	// const categories = 
	const [data, setData ] = useState(Cookie.load('storeItem'));
	
    // const category = categories.filter(cat => cat.id == data.category )[0].cat;
    // console.log(category);
  return (
    <div className='content m-1 px-2 py-5'>
		<h4><b className=" mb-5">Veiw Store Item </b></h4> 
      <div className="card">
		<div className="card-body">
			<form method="post" 
			id="newUser">
				<input type="hidden" name="id" />
				<div className="row">
					<div className="col-md-6 border-right">
						<b className="text-muted">Item Details</b>
						<div className="form-group">
							<label for="" className="control-label">Name</label>
							<input type="text" name="name" className="form-control form-control-sm" 
							value={data.name} readOnly
							/>
						</div>
						<div class="form-group">
							<label className="control-label">Cost Per Sack/Pack  (i.e purchase/production cost) </label>
							<input type="text" class="form-control form-control-sm" name="packCostPrice" 
							value={data.sackCost} readOnly
							/>
						</div>
                        <div class="form-group">
							<label className="control-label">Price Per Sack/Pack (i.e Selling price)</label>
							<input type="text" class="form-control form-control-sm" name="packPrice" 
							value={data.sackPrice} readOnly
							/>
						</div>
						<div className="form-group">
							<label for="" className="control-label">Cost Per Piecies/mudu (i.e Estimated Purchase/production price)</label>
							<input type="text" name="piecesCostPrice" className="form-control form-control-sm" 
							value={data.piecesCost} readOnly
							/>
						</div>
						<div className="form-group">
							<label className="control-label">Price Per Piecies (i.e Selling price)</label>
							<input type="text" className="form-control form-control-sm" name="piecesPrice"  
							value={data.piecesPrice} readOnly
							/>
							
						</div>
                        <div className="form-group">
							<label className="control-label">Available Amount</label>
							<input type="text" className="form-control form-control-sm" name="available"  
							value={data.sackTotal} readOnly
							/>
							
						</div>
                        <div className="form-group">
							<label className="control-label">Sold Amount</label>
							<input type="text" className="form-control form-control-sm" name="sold"  
							value={data.sold} readOnly
							/>
							
						</div>
						<div className="form-group">
							<label className="control-label"> Total No of Sacks Recorded  </label>
							<input type="text" className="form-control form-control-sm" name="packTotal" 
							value={Number(data.sackTotal) + Number(data.sold)} readOnly
							/>
							
						</div>
                        <div className="form-group">
							<label className="control-label"> Total No of Piecies Per Sack  </label>
							<input type="text" className="form-control form-control-sm" name="piecesPerPack" 
							value={data.piecesPerSack} readOnly
							/>
							
						</div>
					</div>
					<div className="col-md-6">
					<b className="text-muted">Other Details </b>
                        <div className="form-group">
							<label className="label control-label">Store location Desc/ID. </label>
							<input type="text" className="form-control form-control-sm" name="shelfLocationNo" 
							value={data.location} readOnly
							/>
						</div>
						<div className="form-group">
							<label className="label control-label">Expiration Date</label>
							<input type="date" className="form-control form-control-sm" name="expirationDate" 
							value={data.expiration} readOnly
							/>
							<small id="pass_match" data-status=''></small>
						</div>
                        <div className="form-group">
							<label className="label control-label">Category</label>
							<input type="text" className="form-control form-control-sm" name="category" 
							value={data.category} readOnly
							/>
							<small id="pass_match" data-status=''></small>
						</div>
						<div className="form-group">
							<label className="label control-label"> First Recored</label>
							<input type="text" className="form-control form-control-sm" name="category" 
							value={data.created_at} readOnly
							/>
							<small id="pass_match" data-status=''></small>
						</div>
						<div className="form-group">
							<label className="label control-label">Last Updated</label>
							<input type="text" className="form-control form-control-sm" name="category" 
							value={data.updated_at} readOnly
							/>
							<small id="pass_match" data-status=''></small>
						</div>
					</div>
				</div>
				<hr />
				
			</form>
		</div>
	</div>
    </div>
  )
}

export default ViewStoreItem