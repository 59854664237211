import React from 'react'
import { Nav, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { FaMailBulk, FaPhone } from 'react-icons/fa'

const Footer = ({activeState}) => {
    const navigate= useNavigate()
    const checkPath = (path) => {
        if(path === activeState){
          return 'active'
        }else{
          return ''
        } 
      }
  return (
    <div>
        <footer className="site-footer" 
        style={{ 
            // backgroundColor:'#553636' 
            }}>
        <div className="container d-flex justify-content-end align-items-center"
        style={{ 
            height: "50px"
         }}
        >
            <div className="text-end copyRight ">
            {/* <p ><b><h6 className=''>&copy; Copyright 2023. All Rights Reserved<span class="uxtech"> www.uxtech.ng </span> </h6> </b></p> */}
           <p className='footer-text my-0'>&copy; Copyright 2023. ABAAFASHAB INTEGRATED CONCEPTS LTD. </p>
           {/* <p className='footer-text my-0 text-center'>Designed by UXTECH </p> */}
           
            </div>
        </div>
        </footer>
    </div>
  )
}

export default Footer
