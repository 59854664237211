import React, { useState } from 'react'
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaCaretDown, FaCaretUp, FACaretUp, FaCartPlus, FaChartBar, FaCog, FaCreditCard, FaHome, FaKey, FaList, FaMicrosoft, FaPlus, FaPlusCircle, FaPoll, FaPollH, FaScrewdriver, FaStore, FaUser, FaUsers } from 'react-icons/fa';
import { useSelector } from 'react-redux';

const SideNav = () => {
    const [subMenuOpen, setSubMenuOpen] = useState(false);
    const Navigate = useNavigate();
    // const user = useSelector(state => state.user.payload);
    useEffect(() => {
        // console.log(window.innerWidth);
        if(window.innerWidth < "430"){
           let element =  document.getElementsByClassName('sidebar')[0];
            element.classList.add('close');
            // document.getElementsByClassName('bell')[0].classList.a 
            
        } 
    }, [])
  return (
    <div class="sidebar bg">
        
    <div id='logo' class="logo">
    <i class="image"> 
        {/* <FaUser /> */}
        <img src='/abaafashab3.png'
          style={{ 
            borderRadius: '50%',
            width:'60px',
            height: '60px',
            // borderRadius: '10%'
           }}
          />
    </i>
    <span class="logo-name mx-1"
    style={{ 
        display: 'flex',
        justifyContent: 'space-between',
        alignContent: 'center'
        
     }}
    >
        Usman Abubakar
        {/* {user.name} */}
        {/* {
            document.getElementById('logo') && 
            document.getElementById('logo').className == 'show' ?
            <FaCaretUp size={12} className='mt-1 ms-2'/> :
            <FaCaretDown size={18} className='mt-1 ms-2' />
        }  */}
    </span>
        {/* <ul style={{ 
            padding: "8px 2px 5px 75px",
            marginTop: "-10px",
            background: "#2a3431",
            display: 'none',
            transition: "all 0.3s ease",
            color: 'white'
         }}> 
            <li>
                Sign-out
            </li>
        </ul> */}
    </div>

    <ul class="nav-list">
    <li onClick={()=> {
        Navigate('dashboard')
        // console.log(window.innerWidth);
        if(window.innerWidth < '430'){
            let sidebar = document.querySelector(".sidebar");
            sidebar.classList.toggle("close");
        }
        }}>
        <a href="#">
        <i class=""><FaHome /></i>
        <span class="link-name">Dashboard</span>
        </a>

        <ul class="sub-menu blank">
        <li><a href="#" class="link-name">Dashboard</a></li>
        </ul>
    </li>

    <li id='users'
     onClick={(e) => {
        document.getElementById('users').classList.toggle('show');
        setSubMenuOpen(!subMenuOpen);
    }}
    >
        <div class="icon-link">
        <a href="#"  >
            <i><FaUsers /></i>
            <span class="link-name">Users</span>
        </a>
        <i class="arrow" > {document.getElementById('users') && document.getElementById('users').className == 'show' ? <FaCaretUp />: <FaCaretDown />} </i>
        
        </div>

        <ul class="sub-menu">
        <li
        onClick={()=> {
            Navigate('new_user')
            if(window.innerWidth < '430'){
                let sidebar = document.querySelector(".sidebar");
                sidebar.classList.toggle("close");
            }
            }}
        >
            <a href="#">
            <FaPlus className='me-1' />
                New User
            </a>
        </li>
        <li
        onClick={()=> {
            Navigate('users')
            if(window.innerWidth < '430'){
                let sidebar = document.querySelector(".sidebar");
                sidebar.classList.toggle("close");
            }
            }}
        >
            <a href="#">
            <FaList className='me-1' />
                User List
            </a>
        </li>
        </ul>
    </li>
    <li id='order'
     onClick={(e) => {
        document.getElementById('order').classList.toggle('show');
        setSubMenuOpen(!subMenuOpen);
    }}
    >
        <div class="icon-link">
        <a href="#"  >
            <i><FaCartPlus /></i>
            <span class="link-name"> Orders</span>
        </a>
        <i class="arrow" > {document.getElementById('order') && document.getElementById('order').className == 'show' ? <FaCaretUp />: <FaCaretDown />} </i>
        
        </div>

        <ul class="sub-menu">
        <li
        onClick={()=> {
            Navigate('new_order')
            if(window.innerWidth < '430'){
                let sidebar = document.querySelector(".sidebar");
                sidebar.classList.toggle("close");
            }
            }}
        >
            <a href="#">
            <FaPlusCircle className='me-1' />
            New Order
            </a>
        </li>
        <li
          onClick={()=> {
            Navigate('orders')
            if(window.innerWidth < '430'){
                let sidebar = document.querySelector(".sidebar");
                sidebar.classList.toggle("close");
            }
            }}
        >
        <a href="#">
            <FaList className='me-1' />
            View Pending Orders
        </a></li>
        </ul>
    </li>
    <li id='transactions'
     onClick={(e) => {
        document.getElementById('transactions').classList.toggle('show');
        setSubMenuOpen(!subMenuOpen);
    }}
    >
        <div class="icon-link ">
        <a href="#">
            <i class="float-end"><FaChartBar /></i>
            <span class="link-name">Transactions </span>
        </a>
        <i class="arrow" > {document.getElementById('transactions') && document.getElementById('transactions').className == 'show' ? <FaCaretUp />: <FaCaretDown />} </i>
        </div>

        <ul class="sub-menu">
        <li
        onClick={()=> {
            Navigate('daily_transactions')
            if(window.innerWidth < '430'){
                let sidebar = document.querySelector(".sidebar");
                sidebar.classList.toggle("close");
            }
            }}
        >
            <a href="#">
            <FaPoll className='me-1' />
                Today Transactions
            </a>
        </li>
        <li
        onClick={()=> {
            Navigate('transactions')
            if(window.innerWidth < '430'){
                let sidebar = document.querySelector(".sidebar");
                sidebar.classList.toggle("close");
            }
            }}
        >
            <a href="#">
            <FaPollH className='me-1' />
                All Transactions
            </a>
        </li>
        </ul>
    </li>
    <li id='reports'
     onClick={(e) => {
        document.getElementById('reports').classList.toggle('show');
        setSubMenuOpen(!subMenuOpen);
    }}
    >
        <div class="icon-link ">
        <a href="#">
            <i class="float-end"><FaChartBar /></i>
            <span class="link-name">Reports </span>
        </a>
        <i class="arrow" > {document.getElementById('reports') && document.getElementById('reports').className == 'show' ? <FaCaretUp />: <FaCaretDown />} </i>
        </div>

        <ul class="sub-menu">
        <li
        onClick={()=> {
            Navigate('daily_reports')
            if(window.innerWidth < '430'){
                let sidebar = document.querySelector(".sidebar");
                sidebar.classList.toggle("close");
            }
            }}
        >
            <a href="#">
            <FaPoll className='me-1' />
                Today Reports
            </a>
        </li>
        <li
        onClick={()=> {
            Navigate('reports')
            if(window.innerWidth < '430'){
                let sidebar = document.querySelector(".sidebar");
                sidebar.classList.toggle("close");
            }
            }}
        >
            <a href="#">
            <FaPollH className='me-1' />
                All Reports
            </a>
        </li>
        </ul>
    </li>
    <li id='store'
     onClick={(e) => {
        document.getElementById('store').classList.toggle('show');
        setSubMenuOpen(!subMenuOpen);
    }}
    >
        <div class="icon-link">
        <a href="#"  >
            <i><FaStore /></i>
            <span class="link-name"> Store</span>
        </a>
        <i class="arrow" > {document.getElementById('store') && document.getElementById('store').className == 'show' ? <FaCaretUp />: <FaCaretDown />} </i>
        
        </div>

        <ul class="sub-menu">
        <li
        onClick={()=> {
            Navigate('add_store_item')
            if(window.innerWidth < '430'){
                let sidebar = document.querySelector(".sidebar");
                sidebar.classList.toggle("close");
            }
            }}
        >
            <a href="#">
            <FaPlus className='me-1' />
            Add Store Item
            </a>
        </li>
        <li
        onClick={()=> {
            Navigate('store_history')
            if(window.innerWidth < '430'){
                let sidebar = document.querySelector(".sidebar");
                sidebar.classList.toggle("close");
            }
            }}
        >
            <a href="#">
            <FaList className='me-1' />
             Store History
            </a>
        </li>
        <li
          onClick={()=> {
            Navigate('store_items')
            if(window.innerWidth < '430'){
                let sidebar = document.querySelector(".sidebar");
                sidebar.classList.toggle("close");
            }
            }}
        ><a href="#">
            <FaList className='me-1' />
             Store Items
        </a></li>
        </ul>
    </li>
    <li id='payment'
     onClick={(e) => {
        document.getElementById('payment').classList.toggle('show');
        setSubMenuOpen(!subMenuOpen);
    }}
    >
        <div class="icon-link ">
        <a href="#">
            <i class="float-end"><FaCreditCard /></i>
            <span class="link-name">Payments </span>
        </a>
        <i class="arrow" > {document.getElementById('payment') && document.getElementById('payment').className == 'show' ? <FaCaretUp />: <FaCaretDown />} </i>
        </div>

        <ul class="sub-menu">
        <li><a href="#">
            <FaPoll className='me-1' />
            Today Payments
        </a></li>
        <li><a href="#">
            <FaPollH className='me-1' />
            All Payments
        </a></li>
        </ul>
    </li>

    <li id='settings'
     onClick={(e) => {
        document.getElementById('settings').classList.toggle('show');
        setSubMenuOpen(!subMenuOpen);
    }}
    >
        <div class="icon-link ">
        <a href="#">
            <i class="float-end"><FaCog /></i>
            <span class="link-name">Settings </span>
        </a>
        <i class="arrow" > {document.getElementById('settings') && document.getElementById('settings').className == 'show' ? <FaCaretUp />: <FaCaretDown />} </i>
        </div>

        <ul class="sub-menu">
        <li
        onClick={()=> {
            Navigate('/profile')
            if(window.innerWidth < '430'){
                let sidebar = document.querySelector(".sidebar");
                sidebar.classList.toggle("close");
            }
            }}
        >
            <a href="#">
            <FaUser className='me-1' />
                User Profile
            </a>
        </li>
        <li
        onClick={()=> {
            Navigate('changePassword')
            if(window.innerWidth < '430'){
                let sidebar = document.querySelector(".sidebar");
                sidebar.classList.toggle("close");
            }
            }}
        >
            <a href="#">
            <FaKey className='me-1' />
                Change Password
            </a>
        </li>
        </ul>
    </li>
    

    {/* Side Nav Footer */}
    <div class="profile-details">
        {/* <div class="profile-content">
            <img src="logo.png" alt="" />
        </div>

        <div class="name-job">
            <div class="name">by uxtech</div>
            <div class="job text-center"> www.uxtech.ng </div>
        </div> */}
        {/* <i class="fas fa-right-to-bracket"></i> */}
    </div>
    </ul>
    </div>
  )
}

export default SideNav