import { createSlice } from "@reduxjs/toolkit";

export const allTimeReviewsSlice = createSlice({
    name: 'allTimeReviews',

    initialState: {

        payload: {
            transactions: 12883,
            salesAmount: 1672632,
            users: 12,
            profit: 172327,
            storeItems: 1233

        }
    },
    reducers: {
        allTimeReviews: (state, action) => {
           state.payload = action.payload
        },
    }
});

export const {allTimeReviews} = allTimeReviewsSlice.actions
export default allTimeReviewsSlice.reducer