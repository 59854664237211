import React, { useEffect, useState } from 'react'
// import { data as storeItems } from './data'
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import Cookie from 'react-cookies';
import { getStoreItems, saveOrder } from '../../api/api';
import { useSelector } from 'react-redux';
const NewOrder = () => {
	const navigate = useNavigate();
	const user = useSelector(state => state.user.payload);
	const storeItems = useSelector(state => state.storeItems.payload); 
	const [storeItem, setStoreItem] = useState(null);
	const [order, setOrder] = useState();
	const [rOrder, setROrder] = useState([]);
	const [orderData, setOrderData] = useState();
	const [status, setStatus] = useState(false);
	const [quantity, setQuantity] = useState(0);
	const [save, setSave] = useState(true);
	const [printFooter, setPrintFooter] = useState(false);
	const [orderRef, setOrderRef] = useState();

	// Getting total and cost total of items purchased.
	let total = 0;
	let costTotal = 0;

	async function fetchStoreItems() {
	 	getStoreItems()
	}
	function PrintElem(recieptId)
	{
		setPrintFooter(true)
		// window.open()
		var mywindow = window.open('', 'PRINT', 'height=400,width=600');

		let oDocument = document.body.innerHTML;
		let head = document.head.innerHTML;
		oDocument = document.getElementById(recieptId).innerHTML;
		mywindow.document.write(head);
        // mywindow.document.head.write('<link rel="stylesheet" href="../css/styles.css">');
        let link = mywindow.document.createElement('link');
        link.setAttribute('rel', 'stylesheet' );
        link.setAttribute('href', '../css/styles.css');
        mywindow.document.head.append(link);
		mywindow.document.write(oDocument);
		// mywindow.document.write('<html><head><title>' + document.title  + '</title>');
		// // mywindow.document.write("<link href='stylesheet' src='../css/styles.css'")
		// mywindow.document.write('</head><body >');
		// mywindow.document.write('<h1>' + document.title  + '</h1>');
		// mywindow.document.write(document.getElementById(recieptId).innerHTML);
		// mywindow.document.write('</body></html>');

		mywindow.document.close(); // necessary for IE >= 10
		mywindow.focus(); // necessary for IE >= 10*/

		mywindow.print();
		mywindow.close();
		setPrintFooter(false);
		return true;
	}
	function print(id){
		let itemToPrint = document.getElementById(id).innerHTML;
		// console.log(itemToPrint);
		let originalBody = document.body.innerHTML;
		let newBody =document.body.innerHTML = itemToPrint;
		window.print();
		document.body.innerHTML = originalBody;
		// window.location.reload();
	}
	const handleOrder =  async (rOrder, recieptId) => {
		// PrintElem(recieptId);
		const res = await saveOrder(orderData);
		console.log(res)
		console.log(orderData);
		if(res.success){
			setOrderRef(res.id);
			fetchStoreItems();
			Swal.fire({
				title: "Successful",
				text: res.message,
				showConfirmButton: true,
				showCancelButton: true,
				confirmButtonColor: 'rgb(39, 54, 45)',
				cancelButtonColor: 'red',
				cancelButtonText: 'Close',
				confirmButtonText: "print reciept."
			}).then((result) => {
				if(result.isConfirmed){
					PrintElem(recieptId);
					setROrder([]);
					setOrder();
					setSave(true);
					
				}else if(result.isDismissed){
					setROrder([]);
					setOrder();
					setSave(true);
				}
			})
		}else{
			Swal.fire({
				icon: 'error',
				text: res.message,
				toast: true,
				timer: 5000,
				timerProgressBar: true,
				showConfirmButton: false,
				position: 'top-end'
			})
		}
	}
	// useEffect(() => {
	// 	console.log(order);
	// 	console.log(rOrder);
	// }, [order, rOrder])
	useEffect(() => {
		status && setOrderData({...orderData, status: 'processed'});
		!status && setOrderData({...orderData, status: 'pending'});
	}, [status])
	useEffect(() => {
		if(storeItems.length == 0) {
			fetchStoreItems();
		}
	}, [])
	useEffect(() => {
		console.log(storeItems)
	}, [storeItems])
	useEffect(() => {
		console.log(order);
	}, [order])
  return (
	
<div className="content m-1 px-2 py-5">
<h4><b className=" mb-5">Create Order  </b></h4> 
	<div className="card">
		<div className="card-body">
			<form action="" id="manage_order"
			onSubmit={(e)=> {
				e.preventDefault()
				
				if(order.type == 'sack' && Number(quantity) <= Number(order.sackTotal) || order.type == 'piecies' &&( Number(quantity)/Number(order.piecesPerSack)) <= Number(order.sackTotal)){
					setROrder([...rOrder, {
						id: order.id,
						name: order.name,
						quantity: quantity,
						price: order.price,
						type: order.type,
						costPrice: order.type == 'sack' ? order.sackCostPrice: order.piecesCostPrice
					}])
					e.target.reset();
					setOrder();
					setQuantity(0);
					setSave(true);
				}else{
					Swal.fire({
						icon: 'warning',
						text: 'Only '+order.sackTotal + "sacks of "+ order.name+ " is available",
						toast: true,
						timer: 5000,
						showConfirmButton: false,
						// position: 'top'
					})
				}
			
			}}
			>
				<input type="hidden" name="id" />
				<div className="row">
				<div className="col-md-6 border-right">
						<b className="text-muted">Search for Item and Add to Order</b>
						<div className="form-group">
                            <select className='form-select my-2' id="items" 
							name='name'
							onChange={(e)=>{
								let data = storeItems.filter((item) => item.name == e.target.value)[0];
								// console.log(data);
								setOrder(data);
								// console.log(order);
							}}
							>
								<option>Search Item...</option>
                                {	
									storeItems &&
									storeItems.map((item) => {
									
										return (
											<option value={item.name}> {item.name}</option>
										)
									})
								}
                            </select>
						</div>
						{
							order && <p className='text-center'><b>{order.sackTotal + ' sacks available'}</b></p>
						}
						<div className="form-group">
							<label> Select Type </label>
                            <select id="price"
							name='price'
							className="form-control"
							onChange={(e) => {
								
								let v = JSON.parse(e.target.value);
								setOrder({
									...order, 
									type: v.type,
									price: v.price,
	
								})
								console.log(v);
							}}
							> 
                                 <option className="text-muted"> ... </option> 
									
								{
									order &&
									<>
									<option value={JSON.stringify({price: order.piecesPrice, type:'piecies'})}>
										{/* {console.log(order)} */}
										Piecies {order.piecesPrice}
									</option>
									<option value={JSON.stringify({price:order.sackPrice, type:'sack'})}>
										Sack {order.sackPrice}
									</option>
									</>
								}
                            </select>
						</div>
						<div className='form-group mt-2'>
							<label> Quantity </label>
							<input
							value={quantity}
							className='form-control'
							type='number' 
							name='quantity' 
							placeholder='Enter quantity. Enter 1/2, or 0.5 for half'
							onChange={(e) => {
								// setOrder({...order, quantity: e.target.value});
								setQuantity(e.target.value);
							}}
							
							/>

						</div>
						<div className="form-group d-flex justify-content-center mt-4 ">
                            <button   type="submit" className="btn btn-md btn-color text-center"  >
                                Add Item
                            </button>
							
						</div>
						
					</div>
					<div className="col-md-6">
					<p><b className="text-muted">Order Items  </b></p>
					{
						rOrder.length == 0 &&
						<small className='text-muted'> Order Items will appear here. </small>
					}
					
					{
						rOrder.length != 0 && 
						<div id='reciept' className=''>
							<div className='row d-flex justify-content-center ' >
								<div  className={ printFooter ? 'reciept col-8 card py-2 mt-4' : ' reciept'}>
								<h6 className='text-center'><b> NAMIB PHAMARCY Order Details </b></h6>
								<table className='table responsive order' 
								style={{ 
									border: '0'
								}}
								>
									<tr
									style={{ 

									}}
									>
										<th>
											SN
										</th>
										<th>
											Description
										</th>
										<th className='me-2'>
											Qty/Type
										</th>
										<th>
											price
										</th>
										{!printFooter && <th className='order-action'>Action </th>}
									</tr>
									{
										rOrder.map((item, i) => {

											let price = item.price * item.quantity;
											let quantity = item.quantity;
											total += price;
											let costPrice = item.costPrice * item.quantity;
											costTotal += costPrice
											
											// setTotalPrice(tPrice);
											return (
												<tr>
													<td> {i+1}</td>
													<td> {item.name}</td>
													<td> {quantity + item.type}</td>
													<td> {price}</td>
													{
														!printFooter &&
														<td className='bg order-action'> 
													
														<button
														type='button'
														className='btn-color'
														onClick={() => {
															// setTotalPrice(totalPrice- price);
															let nOrder = rOrder.filter((o) => o.id != item.id);
															// console.log(nOrder);
															setROrder(nOrder);

															let price = item.price * item.quantity;
															let costPrice = item.costPrice * item.quantity;
															total -= price
															costTotal -= costPrice
															setSave(true);
														}}
														> 
															Remove 
														</button>
														</td>
													}
												</tr>
											)
										})
									}
									<tr aria-colspan={5}>
										<th colSpan={5}> total Price: {total}</th>
									</tr>
									
								</table>
								{
										printFooter &&
										<>
											<span style={{ 
												// border: '0px'
											}}>
												<p className='text-start m-0'
												style={{ 
													border: '0px',
													fontSize: '12px'
												}}
												>
													<b>Tracking ID:</b> nm{orderRef && orderRef} 
												</p>
												<p className='text-center m-0'
												style={{ 
													border: '0px',
													fontSize: '12px',
													fontWeight: 600
												}}
												> Thank You for your patronage.</p>
												<p className='text-center m-0'
												style={{ 
													border: '0px',
													fontSize: '12px',
													fontWeight: 600
												}}
												>
													 Contact us at: 
												</p>
												<p className='text-center m-0'
												style={{ 
													border: '0px',
													fontSize: '12px'
												}}
												>
													<b>Address:</b> Namib Phamarcy Address 
												</p>
												<p className='text-center m-0'
												style={{ 
													border: '0px',
													fontSize: '12px'
												}}
												>
													<b>Phone:</b> 08138282448	<b> Email:</b> namibphamarcy@gmail.com, namibphamarcy@uxtech.ng 
												</p>
												<p className='text-center m-0'
												style={{ 
													border: '0px',
													fontSize: '12px'
												}}
												> Powered by UXTECH</p>
											
											<p className='text-center m-0'
											style={{ 
												// border: '0px',
												fontSize: '9px'
											}}
											> (UX-ELITE TECHNOLOGIES)</p>
											<p className='text-end m-0'
											style={{ 
												// border: '0px',
												fontSize: '9px'
											}}
											> www.uxtech.ng</p>
											</span>
											
										</>
								}
								{
									!printFooter && 
									<>
										<div className='d-flex justify-content-evenly align-items-center order-action'>
											<b
											style={{ 
												fontSize: 14
											}}
											> Tick the box if order will be processed immediately.</b>
											<input type='checkbox' name='status' 
											className=''
											size={'500px'}
											style={{ 
												// fontSize: 200,
												width: '30px',
												height: '30px'
											}}
											onChange={(e) => {
												// console.log(e.target.checked)
												setStatus(e.target.checked);
												setSave(true);
											}}
											/>
										</div>
										<div className='d-flex justify-content-end mt-2'>
											<button className="btn btn-danger me-2 "
											style={{ 
												height: '35px',
												width: '100px'
											}}
											onClick={() => { 
												setROrder([])
												setSave(true);
											}}
											type='button'>
												Clear
											</button> 
											{
												save && 
												<button 
												style={{ 
													height: '35px',
													width: '100px'
												}}
												className="btn btn-color ms-2 "  type='button'
												onClick={() => {
													let items = '';
													let quantities = '';
													let prices = '';
													let types = '';
													let id= '';
													var costPrices = '';

													
													rOrder.map((order) => {
														items.length == 0 ? items = order.name : items += ','+order.name;
														quantities.length == 0 ? quantities = order.quantity : quantities += ','+order.quantity;
														prices.length == 0 ? prices = order.price : prices += ','+order.price;
														costPrices == '' ? costPrices = order.costPrice : costPrices += ','+order.costPrice;
														types.length == 0 ? types = order.type : types += ','+order.type;
														id.length == 0 ? id = order.id : id += ','+order.id;
														console.log(order.costPrice);
													})
													setOrderData({...orderData,
														items,
														quantities,
														prices,
														costPrices: costPrices,
														totalPrice: total,
														totalCostPrice: costTotal,
														types,
														id,
														by: user.email
													});
													setSave(false);
												}}
												>
														Done
												</button>
											}
										</div>
									</>
								}
							</div>
							</div>
						</div>
						
					}
					
					</div>
					
				</div>
				
					
				<hr/>
				{
					!save && 
					(
						<div className="col-lg-12 text-right justify-content-center d-flex">
							<button className="btn btn-danger me-2" type='button'
							onClick={() => navigate('/dashboard')}
							>Cancel</button> 
							<button 
							disabled={save}
							className="btn btn-lg btn-color  ms-2"  type='button'
							onClick={() => handleOrder(rOrder, 'reciept')}
							>Save</button>
						</div>
					)
				}
			</form>
		</div>
	</div>
</div>
  )
}

export default NewOrder