import React, { useEffect, useState } from 'react'
import { FaBars, FaBell, FaUser } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookie from 'react-cookies';
import { useSelector } from 'react-redux';

const TopBar = () => {
  const page = useLocation().pathname.substring(1).toUpperCase()
  const navigate = useNavigate();
  const [userIconboxStatus, setUserIconBoxStatus] = useState(false);
  const dailyReviews = useSelector(state => state.dailyReviews.payload);

  useEffect(() => {
    // document.getElementById('user').classList.toggle('open')
    if(window.innerWidth > '401'){
      // console.log(length)
      // document.querySelector('.bell').style.marginLeft = "calc(100% - 496px)";
     }
     if(window.innerWidth < '401'){
      // console.log(length)
      // document.querySelector('.bell').style.marginLeft = "calc(100% - 160px)";
     }
  }, [])
  useEffect(() => {
    console.log(userIconboxStatus);
  }, [userIconboxStatus])
  return (
    <div class="home-content bg text-light">
        <i class="text-light" onClick={() => {
            let sidebar = document.querySelector(".sidebar");
            sidebar.classList.toggle("close");
            // if(window.innerWidth > 401){
            //   let length = sidebar.classList;
            //   length[2] ? (length = length[2].length) : length = 0; 
            //  if(length == 0){
            //   console.log(length)
            //   document.querySelector('.bell').style.marginLeft = "calc(100% - 496px)";
            //  }else{
            //   console.log(length)
            //   document.querySelector('.bell').style.marginLeft = "calc(100% - 336px)";
            //  }
            // }
        }}>
          <FaBars />
          
        </i>
        <span class="text text-light">{page}</span>
       {/* <div> */}
       {/* <button className='btn btn-sm w-100 btn-light'> Signout </button> */}
       <a className='bell float-end'>
          <FaBell size={25} 
          onClick={() => navigate('/notifications')}
          /> <h className="bell-text"> {dailyReviews.newNotifications}</h> 
          <span id='user' className='ms-4 text-light dropdown'
          > 
              <span
              name='userIcon'
              className='userIcon'
               onClick={() => {
                
                let element = document.getElementById('user');
                // let open = element.classList[3];
                element.classList.toggle('open');
                setUserIconBoxStatus(!userIconboxStatus);
                let body = document.body;
                console.log(userIconboxStatus);

                  // const action = ((e) => {
                  //     if(!userIconboxStatus){
                  //     element.classList.remove('open');
                  //     console.log('condition satisfied.');
                  //     body.removeEventListener('click', action);
                  //   }else{
                  //   console.log('condition not satisfied')
                  //   }
                  //   console.log('event running.')
                  // })
                  // body.addEventListener('click', action)
                
               
              }}
              >
                  <FaUser
                  // id='userIcon'
                  // size={25} 
                 
                  /> 
              </span>
              <div class="dropdown-content ">
                <button className='form-control'
                onClick={()=> navigate('/notifications')}
                > Notifications </button>
                <button
                onClick={()=>{
                  Cookie.remove('token');
                  window.start_load()
                  setTimeout(() => {
                    navigate('/');
                    window.stop_load();
                  }, 2000)
                }}
                className='form-control'> Sign-out</button>
              </div>
          </span>
          
        </a>
       {/* </div> */}
    </div>
  )
}

export default TopBar