import React from 'react'
import { useSelector } from 'react-redux'

const Partners = () => {
    const partners = useSelector(state => state.partners.payload);
  return (
    <div className='row py-5'>
        <p className='small-header mt-2 text-center'> <u>OUR PARTNERS</u>  </p>
        
            {
                partners.map((partner) => (
                <div className='col-lg-3 col-md-3 col-sm-6 col-xs-12'>
                    <div className='d-flex justify-content-center text-center align-items-center' 
                    style={{ 
                        
                    // color: 'white',
                    padding: '5px'
                    }}>
                          <span className='text-center bg-primary d-flex justify-content-center text-center align-items-center'
                          style={{ 
                            borderRadius:'100%',
                        width: '150px',
                        height: '150px',
                        backgroundColor: 'navy',
                           }}
                          > 
                          <img src={partner.img} width={'150px'} height={'150px'} 
                          style={{ 
                            borderRadius: '100%'
                           }}
                          />
                          </span>
                    </div>
                </div>
                ))
            }
        
        
        <div className='col-lg-4 col-md-4 col-sm-6 col-xs12'>

        </div>
    </div>
  )
}

export default Partners