import React, {useState, useEffect} from 'react';
import {Card, Row, Col, Button} from 'react-bootstrap';
import { dailyReviews, overallReviews } from '../services/dashboardServices';
import {FaChartLine, FaChartBar, FaBars, FaCoins, FaMoneyBill, FaBell} from 'react-icons/fa'
import { useSelector } from 'react-redux';
// import { getAllTimeReviews, getDailyReviews } from '../api/api';


const Dashboard = () => {
//    const myStyle = useSelector(state => state.style.payload);
//    const user = useSelector(state => state.user.payload);
   const dReviews = useSelector(state => state.dailyReviews.payload);
   const atReviews = useSelector(state => state.allTimeReviews.payload);

//    function fetchDailyReviews(){
//     cons
//    }

useEffect(() => {
    // dReviews.length == 0 && getDailyReviews();
    // atReviews.length == 0 && getAllTimeReviews();
    console.log(dReviews);
    console.log(atReviews);
}, [dReviews])
   useEffect(() => {
    // console.log(myStyle.marginLeft);
   }, [])
  return dReviews && atReviews && (
   
<div className='mt-2 m-0 pt-4'>
{/* <div className='col-lg-2 col-sm-2 col-xs-2' >
<MySidebar/>
</div> */}
{/* <MySidebar/> */}

<div id='' className='position-relative top-0 end-0  '
style={{  }}
>
<h2 className='text-center my-2'> <b> Report Summaries </b>  </h2>
    <div className='mt-2 mx-1 my-4 card'>
        <h4 className='text-start ms-4 mt-2'> <b>Daily Reviews</b> </h4>
        <div className='d-flex justify-content-end align-items-center pe-4'>
                <h5> Goto</h5>
                <input className='form-control ms-2'
                style={{ 
                    width: '200px'
                 }}
                //  onChange={(e) => getDailyReviews({date:e.target.value})}
                type='date' name='date' />
                </div>
            <div className='row mx-2'>
                
            { dailyReviews && dailyReviews.map((daily, idx) => (
                <div className='col-xs-12 col-sm-6 colmd-4 col-lg-3'> 
                    <div className='d-flex justify-content-center card dashboard-card my-2'>
                        <>
                        <h6 className='title'>
                        {
                        daily.icon === 'trans' && <FaChartLine size='30' /> 
                        }
                        {
                        daily.icon === 'sales' && <FaChartBar size='30' />
                        }
                        {
                        daily.icon === 'sProfit' && <FaMoneyBill size='30' /> 
                        }
                        {
                        daily.icon === 'fProfit' && <FaCoins size='30' />
                        }
                        {
                        daily.icon === 'notification' && <FaBell size='25' />
                        }

                        <b> {` ${daily.title}`} </b>
                        </h6> 
                        <small className='body'>
                            {daily.icon == 'trans' && ` ${dReviews.transactions}`}
                            {daily.icon == 'sales' && ` ₦${dReviews.salesAmount}`}
                            {daily.icon == 'sProfit' && ` ₦${dReviews.profit}`}
                            {daily.icon == 'notification' && <a href='#'
                            style={{ 
                                textDecoration: 'none',
                                color: 'black',
                                fontWeight: 400
                             }}
                            ><i>{dReviews && dReviews.notification && dReviews.notification.slice(0, 40)}...</i></a>}
                            {/* {  daily.old && 
                            <span>
                                <b>Read: </b> {daily.old}
                            </span>
                            } */}
                        </small>
                        </>
                    </div>
                </div>
            ))}
            </div>
    </div>

    <div className='mt-2 mx-1 card my-5'>
        <h4 className='text-start ms-4 mt-2'> <b>Overall Summary</b> </h4>
            <div className='row mx-2'>
            {overallReviews.map((overall, idx) => (
                <div className='col-xs-12 col-sm-6 colmd-4 col-lg-3'> 
                <div className='d-flex justify-content-center card dashboard-card my-2'>
                     <>
                        <h6 className='title'>
                        {overall.title}
                        </h6>
                        <small className='body'> 
                        {overall.icon == 'users' && ` ${atReviews.users}`}
                        {overall.icon == 'transaction' && ` ${atReviews.transactions}`}
                        {overall.icon == 'sales' && `₦${atReviews.salesAmount}`}
                        {overall.icon == 'profit' && ` ₦${atReviews.profit}`}
                        {overall.icon == 'store' && ` ${atReviews.storeItems}`}
                        </small>
                    </>
                    </div>
                </div>
            ))}
            </div>
    </div>
</div>
</div>

  )
}

export default Dashboard