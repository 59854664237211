import { createSlice } from "@reduxjs/toolkit";

export const dailyReviewsSlice = createSlice({
    name: 'dailyReviews',

    initialState: {

        payload: {
           transactions: 12,
           profit: 1500055,
            notification: 'Report from Usman',
            salesAmount: 123453
        }
    },
    reducers: {
        dailyReviews: (state, action) => {
           state.payload = action.payload
        },
    }
});

export const {dailyReviews} = dailyReviewsSlice.actions
export default dailyReviewsSlice.reducer