import { createSlice } from '@reduxjs/toolkit'
const img1 = "./assets/images/sliders/slider1.jpg";
const img2 = "./assets/images/sliders/slider2.webp";

const sliderSlice = createSlice({
    name: 'slider',
    initialState: {
      sliders: [
        {
          img: img2,
          title: 'Image 2'
        },
        // {
        //   img: img1,
        //   title: 'Image 1'
        // },
      ]
    }
    ,
    reducers: {
        updateSlider: (state, action) => {
            state.sliders = action.payload;
        }
    }
})

export const {updateSlider} = sliderSlice.actions;
export default sliderSlice.reducer;
