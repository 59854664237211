import react, {useEffect, useMemo} from 'react';
import MyTable from '../components/myTable';
import {useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Cookie from 'react-cookies';
// import { userList } from '../api/api';

const Users = () => {
    // const marginLeft = useSelector(state => state.style.payload.marginLeft)
    const navigate = useNavigate();
    const data = useSelector(state => state.userList.payload);
    // let data = [
    //     {
    //         id: 1,
    //         name: 'usman abubakar',
    //         occupation: 'Full Stack Software Engineer',
    //         level: 'Senior'
    //     },
    //     {
    //         id: 1,
    //         name: 'fatima muhammad',
    //         occupation: 'UI/UX DESIGNER',
    //         level: 'Senior'
    //     },
    //     {
    //         id: 1,
    //         name: 'usman abubakar',
    //         occupation: 'Full Stack Software Engineer',
    //         level: 'Senior'
    //     },
    //     {
    //         id: 1,
    //         name: 'fatima muhammad',
    //         occupation: 'UI/UX DESIGNER',
    //         level: 'Senior'
    //     },
    //     {
    //         id: 1,
    //         name: 'usman abubakar',
    //         occupation: 'Full Stack Software Engineer',
    //         level: 'Senior'
    //     },
    //     {
    //         id: 1,
    //         name: 'fatima muhammad',
    //         occupation: 'UI/UX DESIGNER',
    //         level: 'Senior'
    //     },
    //     {
    //         id: 1,
    //         name: 'usman abubakar',
    //         occupation: 'Full Stack Software Engineer',
    //         level: 'Senior'
    //     },
    //     {
    //         id: 1,
    //         name: 'fatima muhammad',
    //         occupation: 'UI/UX DESIGNER',
    //         level: 'Senior'
    //     },
    //     {
    //         id: 1,
    //         name: 'usman abubakar',
    //         occupation: 'Full Stack Software Engineer',
    //         level: 'Senior'
    //     },
    //     {
    //         id: 1,
    //         name: 'fatima muhammad',
    //         occupation: 'UI/UX DESIGNER',
    //         level: 'Senior'
    //     },
    //     {
    //         id: 1,
    //         name: 'usman abubakar',
    //         occupation: 'Full Stack Software Engineer',
    //         level: 'Senior'
    //     },
    //     {
    //         id: 1,
    //         name: 'fatima muhammad',
    //         occupation: 'UI/UX DESIGNER',
    //         level: 'Senior'
    //     },
    //     {
    //         id: 1,
    //         name: 'usman abubakar',
    //         occupation: 'Full Stack Software Engineer',
    //         level: 'Senior'
    //     },
    //     {
    //         id: 1,
    //         name: 'fatima muhammad',
    //         occupation: 'UI/UX DESIGNER',
    //         level: 'Senior'
    //     }

    // ]
    // const getUsers = async () => {
    //     window.start_load();
    //     const res = await userList({token:Cookie.load('token')});
    //     // if(!res.succes)
    //     window.stop_load();
    // }
    const columns = useMemo( () => [
        {
            Header: '#',
            accessor: (data, i) => {
                return (i+1)
            }
        },
        {
            Header: 'name',
            accessor: 'name',
        },
        {
            Header: 'Email',
            accessor: 'email'
        },
        {
            Header: 'Phone',
            accessor: 'phone'
        },
        {
            Header: 'type',
            accessor: (data) => data.type == 1 ? 'Admin' : 'Regular'
        },
        {
            Header: 'status',
            accessor: (data) => data.status == 1 ? 'Active': 'Disabled'
        },
        {
            Header: 'Action',
            accessor: (data) => 
                (
                    <button className="form-control btn-sm btn-color"
                    style={{ 
                        fontSize: 12
                     }}
                    onClick={() => {
                        Cookie.save('editData', JSON.stringify(data));
                        navigate("/edit_user")}}
                    >
                        View
                    </button>
                )
            
        }
    ], [])

    useEffect(() => {
        // data.length == 0 && getUsers();
        console.log(data)
    }, [data])
    return data.length > 0 ? (
        <div className='main'> 
            {/* <h2> Users </h2> */}
            <MyTable data ={data} columns={columns} pageName = {"Users List"}/>
        </div>
    ):
    (
        <div className=' row justify-content-center py-5'>
            <h4> <b className="mb-5 ms-5">User List</b> </h4>
            <h3> <p className="mb-5 ms-5 text-center">User List is Empty.</p> </h3>
        </div>
    )
}

export default Users