import axios from "axios";
import Cookie from 'react-cookies';
// const token = Coo;
const Axios = axios.create({
    baseURL: 'https://uxtech.com.ng/abaafashab',
    headers: {
        "Authorization": "Bearer "+Cookie.load('token')
    }
});

export default Axios;