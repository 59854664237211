import React from 'react'
import Contact from './components/contact'
import Header from './components/Header'
import Footer from './components/footer'

const ContactUs = () => {
  return (
    <div>
        <Header />
        <div style={{ backgroundColor: '#9EDEFF' }}>
        <h1 className='ms-5 pt-3 text-center' > <b> <i>  Contact Us </i></b> </h1>
        {/* <h4> Send us a message and we will get back to your through your mail/phone. </h4>  */}
        <Contact />
        </div>
        <Footer />
    </div>


  )
}

export default ContactUs