import { createSlice } from '@reduxjs/toolkit'
const img1 = "/assets/images/clients/passport.jpg";
const img2 = "/assets/images/clients/agent.png";

const clientSlice = createSlice({
    name: 'clients',
    initialState: {
      payload: [
        {
          img: img2,
          name: 'Dr. Usman Abubakar',
          remark: 'ABAAFASHAB provides premium services like no other. We are glad to have our records sets straight again. Regards.',
          position: 'Chief Technology Officer',
          company: 'UX ELITE TECHNOLOGIES'
        },
        {
          img: img1,
          name: 'Hon. Abubakar Suleiman',
          remark: 'ABAAFASHAB provides premium services like no other. We are glad to have our records sets straight again. Regards.',
          position: 'Chief Executive Officer',
          company: 'WeBank LTD'
        },
        {
            img: img2,
            name: 'Dr. Usman Abubakar',
            remark: 'ABAAFASHAB provides premium services like no other. We are glad to have our records sets straight again. Regards.',
            position: 'Chief Technology Officer',
            company: 'UX ELITE TECHNOLOGIES'
          },
          {
            img: img1,
            name: 'Hon. Abubakar Suleiman',
            remark: 'ABAAFASHAB provides premium services like no other. We are glad to have our records sets straight again. Regards.',
            position: 'Chief Executive Officer',
            company: 'WeBank LTD'
          },
          {
            img: img2,
            name: 'Dr. Usman Abubakar',
            remark: 'ABAAFASHAB provides premium services like no other. We are glad to have our records sets straight again. Regards.',
            position: 'Chief Technology Officer',
            company: 'UX ELITE TECHNOLOGIES'
          },
          {
            img: img1,
            name: 'Hon. Abubakar Suleiman',
            remark: 'ABAAFASHAB provides premium services like no other. We are glad to have our records sets straight again. Regards.',
            position: 'Chief Executive Officer',
            company: 'WeBank LTD'
          },
      ]
    }
    ,
    reducers: {
        clients: (state, action) => {
            state.sliders = action.payload;
        }
    }
})

export const {clients} = clientSlice.actions;
export default clientSlice.reducer;
