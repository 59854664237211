import { createSlice } from "@reduxjs/toolkit";

export const storeItemsSlice = createSlice({
    name: 'storeItems',

    initialState: {

        payload: [
           
        ]
    },
    reducers: {
        storeItems: (state, action) => {
           state.payload = action.payload
        },
    }
});

export const {storeItems} = storeItemsSlice.actions
export default storeItemsSlice.reducer