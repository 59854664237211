import { createSlice } from '@reduxjs/toolkit'
const img1 = "./assets/images/partners/logo512.png";
const img2 = "./assets/images/partners/logo-full.png";

const partnersSlice = createSlice({
    name: 'partners',
    initialState: {
      payload: [
        {
          img: img2,
          title: 'Image 2'
        },
        {
          img: img1,
          title: 'Image 1'
        },
        {
            img: img2,
            title: 'Image 2'
          },
          {
            img: img1,
            title: 'Image 1'
          },
      ]
    }
    ,
    reducers: {
        partners: (state, action) => {
            state.sliders = action.payload;
        }
    }
})

export const {partners} = partnersSlice.actions;
export default partnersSlice.reducer;
