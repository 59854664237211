import React, {useState, useEffect} from 'react'
import { Form } from 'react-bootstrap'
import Swal from 'sweetalert2';
// import { updateStoreItem } from '../api/api';
import { categories } from './data';
import Cookie from 'react-cookies';
import { useNavigate } from 'react-router-dom';
const UpdateStoreItem = () => {
	// const categories = 
	const item = Cookie.load('storeItem');
	const [data, setData ] = useState();
	const navigate = useNavigate();
	const [show, setShow] = useState({
		pack: false,
		usage: false,
		expiration: false,
		location: false,
		costPackPrice: false,
		costPieciesPrice:false,
		packPrice: false,
		pieciesPrice:false,
		category: false,
		pieciesPerPack: false,
		done: false

	})
    const handleChange = (e) => {
        
        setData({ ...data, [e.target.name] : e.target.value});

    }

	// const handleSubmit = async (e) => {
	// 	e.preventDefault();
	// 	window.start_load();
	// 	const res = await updateStoreItem(data);
	// 	console.log(res);
	// 	if(res.success){
	// 		e.target.reset();
	// 		setData({});
	// 		Swal.fire("Success", res.message, 'success');
	// 		navigate('/store_items');
	// 	}else{
	// 		Swal.fire('Failed', res.message, 'info');
	// 	}
	// 	window.stop_load();
	// }
	useEffect(() => {
		console.log(data);
	}, [data])
	useEffect(() => {
		setData({...data, id:item.id})
	},[])
  return (
    <div className='content m-1 px-2 py-5'>
		<h4><b className=" mb-5">Update Store Item </b></h4> 
      <div className="card">
		<div className="card-body">
			<form method="post" 
			// onSubmit={handleSubmit}
			id="newUser">
				<input type="hidden" name="id" />
				<div className="row justify-content-center">
					<div className='col-8'>
						<h4 className="text-muted text-center"><b>Item Name </b></h4>
						<div className="form-group">
							{/* <label for="" className="control-label text-center">Name</label> */}
							<input type="text" name="name" className="form-control form-control-sm" 
							value={item.name}
							readOnly
							onChange={handleChange}
							/>
						</div>
						<h5 className='text-muted text-center'> <b>Tick the boxes of the properties you will like to update. </b></h5>
						{/* <p 
						style={{ fontSize:11 }}
						className='text-danger text-center'> <b>Note:</b> The new values will update the item in the database. </p> */}

					</div>
					{	!show.done &&
						/* Check boxes to indicate which properties to update. */
						<div className='row justify-content-center'>
						<div className='col-6'>
							<div className='d-flex '>
							
							<input type='checkbox' name='pack' 
							className='mx-1'
							size={'500px'}
							style={{ 
								// fontSize: 200,
								width: '30px',
								height: '30px'
							}}
							onChange={(e) => {
								setShow({...show, pack: true});
							}}
							/>
							<p> New Packs have been Purchased</p>
							</div>
							<div className='d-flex '>
							<input type='checkbox' name='costPackPrice' 
							className='mx-1'
							size={'500px'}
							style={{ 
								// fontSize: 200,
								width: '30px',
								height: '30px'
							}}
							onChange={(e) => {
								setShow({...show, costPackPrice: true});
							}}
							/>
							<p> Change Pack Cost Price </p>
							</div>
							<div className='d-flex '>
							<input type='checkbox' name='costPieciesPrice' 
							className='mx-1'
							size={'500px'}
							style={{ 
								// fontSize: 200,
								width: '30px',
								height: '30px'
							}}
							onChange={(e) => {
								setShow({...show, costPieciesPrice: true});
							}}
							/>
							<p> Change Piecies Cost Price </p>
							</div>
							<div className='d-flex '>
							<input type='checkbox' name='packPrice' 
							className='mx-1'
							size={'500px'}
							style={{ 
								// fontSize: 200,
								width: '30px',
								height: '30px'
							}}
							onChange={(e) => {
								setShow({...show, packPrice: true});
							}}
							/>
							<p> Change Pack Selling Price </p>
							</div>
							<div className='d-flex '>
							<input type='checkbox' name='pieciesPrice' 
							className='mx-1'
							size={'500px'}
							style={{ 
								// fontSize: 200,
								width: '30px',
								height: '30px'
							}}
							onChange={(e) => {
								setShow({...show, pieciesPrice: true});
							}}
							/>
							<p> Change Piecies Selling Price </p>
							</div>
						</div>
						<div className='col-6'>
							<div className='d-flex '>
							
							<input type='checkbox' name='pieciesPerPack' 
							className='mx-1'
							size={'500px'}
							style={{ 
								// fontSize: 200,
								width: '30px',
								height: '30px'
							}}
							onChange={(e) => {
								setShow({...show, pieciesPerPack: true});
							}}
							/>
							<p> Update No. of Piecies Per Pack</p>
							</div>
							<div className='d-flex '>
							<input type='checkbox' name='usage' 
							className='mx-1'
							size={'500px'}
							style={{ 
								// fontSize: 200,
								width: '30px',
								height: '30px'
							}}
							onChange={(e) => {
								setShow({...show, usage: true});
							}}
							/>
							<p> Update Usage Description </p>
							</div>
							<div className='d-flex '>
							<input type='checkbox' name='location' 
							className='mx-1'
							size={'500px'}
							style={{ 
								// fontSize: 200,
								width: '30px',
								height: '30px'
							}}
							onChange={(e) => {
								setShow({...show, location: true});
							}}
							/>
							<p> Update Shelf Location </p>
							</div>
							<div className='d-flex '>
							<input type='checkbox' name='expiration' 
							className='mx-1'
							size={'500px'}
							style={{ 
								// fontSize: 200,
								width: '30px',
								height: '30px'
							}}
							onChange={(e) => {
								setShow({...show, expiration: true});
							}}
							/>
							<p> Update Expiration Date </p>
							</div>
							<div className='d-flex '>
							<input type='checkbox' name='category' 
							className='mx-1'
							size={'500px'}
							style={{ 
								// fontSize: 200,
								width: '30px',
								height: '30px'
							}}
							onChange={(e) => {
								setShow({...show, category: true});
							}}
							/>
							<p> Update Category </p>
							</div>
						</div>

						<div className='col-12'><center>  <button 
						onClick={() => setShow({...show, done:true})}
						type='button' className='btn btn-color my-1'> Done </button> </center> </div>
					</div>
					/* End Tick Check boxes to update.  */
					}
					{
						show.done &&
						<>
							<div className="col-md-6 border-right">
							{/* <b className="text-muted">Item Details </b> */}
								{	show.costPackPrice &&
									<div class="form-group">
										<label className="control-label">Cost Price per pack (i.e purchase Price)</label>
										<input type="text" class="form-control form-control-sm" name="packCostPrice" 
										// value={data.packCostPrice}
										onChange={handleChange}
										/>
									</div>
								}
								{	show.packPrice &&
									<div class="form-group">
										<label className="control-label">Price per pack (i.e Selling price)</label>
										<input type="text" class="form-control form-control-sm" name="packPrice" 
										// placeholder='Only fill me if you are changing pack selling price.'
										// value={data.packPrice}
										onChange={handleChange}
										/>
									</div>
								}
								{	show.costPieciesPrice &&
									<div className="form-group">
										<label for="" className="control-label">Cost Price Per Piecies (i.e Estimated Purchase price)</label>
										<p style={{ fontSize:10 }} className='text-muted m-0'>Only fill me if the cost pieces/card price has changed.</p>
										<input type="text" name="piecesCostPrice" className="form-control form-control-sm" 
										// placeholder='Only fill me if the cost pieces/card price has changed.'
										// value={data.piecesCostPrice}
										onChange={handleChange}
										/>
									</div>
								}
								{	show.pieciesPrice &&
									<div className="form-group">
										<label className="control-label">Price Per Piecies (i.e Selling price)</label>
										<p style={{ fontSize:10 }} className='text-muted m-0'>Only fill me if you are changing piecies/card selling price.</p>
										<input type="text" className="form-control form-control-sm" name="piecesPrice"  
										// placeholder='Only fill me if you are changing piecies/card selling price.'
										// value={data.piecesPrice}
										onChange={handleChange}
										/>
										
									</div>
								}
								{	show.pack &&
									<div className="form-group">
										<label className="control-label"> Total No of Packs  </label>
										<input type="text" className="form-control form-control-sm" name="packTotal" 
										placeholder='Enter the new total No of packs purchased'
										// value={data.packTotal}
										onChange={handleChange}
										/>
										
									</div>
								}
								
							</div>
							<div className="col-md-6">
							{	show.pieciesPerPack && 
								<div className="form-group">
									<label className="control-label"> Total No of Piecies Per Pack  </label>
									<input type="text" className="form-control form-control-sm" name="piecesPerPack" 
									placeholder='Enter the new total No of piecies/card'
									// value={data.piecesPerPack}
									onChange={handleChange}
									/>
									
								</div>
							}
							{	show.usage &&
								<div className="form-group">
									<label className="control-label"> Usage  </label>
									<p style={{ fontSize:10 }} className='text-muted m-0'>fill me only if there is change in the existing usage description.</p>
									<input type="text" className="form-control form-control-sm" name="usage" 
									// placeholder='fill me only if there is change in the existing usage description'
									// value={data.usage}
									onChange={handleChange}
									/>
									
								</div>
							}
							{	show.location &&
								<div className="form-group">
									<label className="label control-label">shelf location No. </label>
									<input type="text" className="form-control form-control-sm" name="shelfLocationNo"
									placeholder='Only fill me if location has changed'
									// value={data.shelfLocationNo}
									onChange={handleChange}
									/>
								</div>
							}
							{	show.expiration &&
								<div className="form-group">
									<label className="label control-label">Expiration Date</label>
									<input type="date" className="form-control form-control-sm" name="expirationDate" 
									// value={data.expirationDate}
									onChange={handleChange}
									/>
									<small id="pass_match" data-status=''></small>
								</div>
							}
							{	show.category && 
								<div className="form-group">
									<label for="" className="control-label">Category</label>
									<Form.Select size='sm' name="category" id="category" className="form-control"
									onChange={handleChange}
									// value={data.category}
									>
										<option >Select Item Category... </option>
										{
											categories.map((cat) => (
												<option value={cat.id} >{cat.cat} </option>
											))
										}
									</Form.Select>
									<small><i></i></small>
								</div>
							}
							</div>
						</>
					}
				</div>
				<hr />
				{	show.done &&
					<div className='row justify-content-center'>
						<div className="col-lg-6 col-md-6 col-sm-8 col-xs-10 col-xxs-12 text-right d-flex justify-content-center ">
							{/* <input type='submit' className="btn btn-color mr-2" id="submit-btn"
							
							value="Save" /> */}
							<button type='button' className="btn btn-danger btn-md"
							onClick={()=> {
								navigate('/store_items');
							}}
							>
								Back
							</button>
							<button
							type='submit'
							className="btn btn-color ms-2">
								Update
							</button> 
							
						</div>
					</div>
				}
			</form>
		</div>
	</div>
    </div>
  )
}

export default UpdateStoreItem