import { configureStore } from "@reduxjs/toolkit"
import sliderSlice from "./slices/sliderSlice";
import servicesSlice from "./slices/servicesSlice";
import partnersSlice from "./slices/partnersSlice";
import clientSlice from "./slices/clientSlice";
import dailyReviewsSlice from './slices/dailyReviewSlice';
import allTimeReviewsSlice from "./slices/allTimeReviews"
import userList  from "./slices/userListSlice";
import storeItems from "./slices/storeItems";
import userSlice from "./slices/userSlice";


export const store = configureStore({
    reducer: {
        user: userSlice,
        storeItems,
        userList,
        sliders:sliderSlice,
        partners: partnersSlice,
        services: servicesSlice,
        clients: clientSlice,
        dailyReviews: dailyReviewsSlice,
        allTimeReviews: allTimeReviewsSlice
    }
})
export default store;