import react, {useEffect, useMemo, useState} from 'react';
import MyTable from '../components/myTable';
import { useNavigate } from 'react-router-dom';
import Cookie from 'react-cookies';
import { getStoreItems } from '../../api/api'
import { categories } from './data';
import { useSelector } from 'react-redux';
const StoreItems = () => {
    const navigate = useNavigate();
    let d = Cookie.load('store') !== 'undefined' ? Cookie.load('store') : [];
    // const [data, setData] = useState([]);
    const data = useSelector(state => state.storeItems.payload);

   
    const fetchStoreItems = async () => {
        // window.start_load();
        const res = await getStoreItems({token:Cookie.load('token')});
        if(res.success){
            // Cookie.save('store', JSON.stringify(res.data));
            // setData(res.data);
        }
        // window.stop_load();
        // if(!res.succes)
    }
    const columns = useMemo( () => [
        {
            Header: '#',
            accessor: (data, i) => {
                return (i+1)
            }
        },
        {
            Header: 'Item',
            accessor: 'name',
        },
        {
            Header: 'Category',
            accessor: 'category',
        },
        {
            Header: 'Packs/Sacks Available',
            accessor: 'sackTotal'
        },
        {
            Header: 'Total Sold',
            accessor: ((d) => (d.sold ? d.sold : 0))
        },
        {
            Header: 'AT Total',
            accessor: ((d) => (d.sold ? d.sackTotal + d.sold : d.sackTotal))
        },
        {
            Header: 'Action',
            accessor: (data, i) => 
                (
                    <span id='' className='drop-down'
                    style={{ width: '150px' }}
                    > 
                        <button
                        // id='action'
                        className='form-select'
                         style={{ 
                            fontSize: 12
                          }}
                        onClick={() => {
                            let element = document.getElementById('child'+i);
                            
                            element.classList.toggle('open');
                        }}
                        >
                            Action
                        </button>
                        <div id={'child'+i} class="table-action-box form-control">
                             <button className='btn form-control text-start'
                             style={{ 
                                fontSize: 12
                              }}
                             onClick={() => {
                                Cookie.save('storeItem', JSON.stringify(data));
                                navigate('/view_store_item');
                             }}
                             > View </button>

                             <button className='btn form-control text-start'
                             style={{ 
                                fontSize: 12
                              }}
                             onClick={() => {
                                Cookie.save('storeItem', JSON.stringify(data));
                                navigate('/update_store_item');
                             }}
                             > Update </button>
                        </div>
                    </span>
                )
            
        }
    ], [])

    useEffect(() => {
        // Cookie.load('store') != 'undefined' && setData(Cookie.load('store'))
        data.length == 0 && getStoreItems();
        console.log(data)
        
    }, [data])

    return data.length != 0 ? (
        <div className=''> 
            {/* <h2> Users </h2> */}
            <MyTable data ={data} columns={columns} pageName = {"Store Items"} 
            button = {<button className='btn btn-md btn-color'
            style={{ 
                // width:'170px',
                height: '40px'
             }}
            onClick={()=> getStoreItems()}
            > Reload Store Items </button>}
            />
        </div>
    ):
    (
        <div className=' row justify-content-center py-5'>
            <h4> <b className="mb-5 ms-5">Store Items </b> </h4>
            <h3> <p className="mb-5 ms-5 text-center">Store is Empty.</p> </h3>
        </div>
    )
}

export default StoreItems