import React, {useState, useEffect} from 'react'
import { Form } from 'react-bootstrap'
import Swal from 'sweetalert2';
// import { addStoreItem } from '../api/api';
import { categories } from './data';
import { useNavigate } from 'react-router-dom';
const AddStoreItem = () => {
	// const categories = 
	const navigate = useNavigate();
	const [data, setData ] = useState({
       
    });

    const handleChange = (e) => {
        
        setData({ ...data, [e.target.name] : e.target.value});

    }

	// const handleSubmit = async (e) => {
	// 	e.preventDefault();
	// 	window.start_load();
	// 	const res = await addStoreItem(data);
	// 	console.log(res);
	// 	if(res.success){
	// 		e.target.reset();
	// 		setData({});
	// 		Swal.fire("Success", res.message, 'success');
	// 	}else{
	// 		Swal.fire('Failed', res.message, 'info');
	// 	}
	// 	window.stop_load();
	// }
	useEffect(() => {
		console.log(data);
	}, [data])
  return (
    <div className='content m-1 px-2 py-5'>
		<h4><b className=" mb-5">Add New Store Item </b></h4> 
      <div className="card">
		<div className="card-body">
			<form method="post" 
			// onSubmit={handleSubmit} 
			id="newUser">
				<input type="hidden" name="id" />
				<div className="row">
					<div className="col-md-6 border-right">
						<b className="text-muted">Item Details</b>
						<div className="form-group">
							<label for="" className="control-label fw-bold">Name</label>
							<input type="text" name="name" className="form-control form-control-sm" required
							onChange={handleChange}
							/>
						</div>
						<div class="form-group">
							<label className="control-label fw-bold">Cost per Sack/Pack (i.e purchase/production cost)</label>
							{/* <p
							style={{ fontSize: 11 }} className='text-muted my-0'
							> This is need for the purpose of automated profit calculation. </p> */}
							<input type="text" class="form-control form-control-sm" name="packCostPrice" required
							placeholder='This is need for the purpose of automated profit calculation.'
							onChange={handleChange}
							/>
						</div>
                        <div class="form-group">
							<label className="control-label fw-bold">Price per Pack/Sack (i.e Selling price) </label>
							<input type="text" class="form-control form-control-sm" name="packPrice" required
							placeholder='i.e Selling price'
							onChange={handleChange}
							/>
						</div>
						<div className="form-group">
							<label for="" className="control-label fw-bold"
							style={{ fontSize: 14 }}
							>Cost Per Piecies/mudu (i.e Estimated Purchase/production price)</label>
							{/* <p
							style={{ fontSize: 11 }} className='text-muted my-0'
							> This is need for the purpose of automated profit calculation. </p> */}
							<input type="text" name="piecesCost" className="form-control form-control-sm" required
							placeholder='This is need for the purpose of automated profit calculation.'
							onChange={handleChange}
							/>
						</div>
						<div className="form-group">
							<label className="control-label fw-bold">Price Per Piecies/Mudu (i.e Selling price) </label>
							<input type="text" className="form-control form-control-sm" name="piecesPrice" 
							placeholder='i.e Selling price' 
							onChange={handleChange}
							/>
							
						</div>
						<div className="form-group">
							<label className="control-label fw-bold"> Total No of Sack/Pack  </label>
							<input type="text" className="form-control form-control-sm" name="packTotal" 
							onChange={handleChange}
							/>
							
						</div>
                        <div className="form-group">
							<label className="control-label fw-bold"> Total No of Piecies Per Sack/Pack  </label>
							<input type="text" className="form-control form-control-sm" name="piecesPerPack" 
							onChange={handleChange}
							/>
							
						</div>
					</div>
					<div className="col-md-6">
					<b className="text-muted">Other Details </b>
						
                    <div className="form-group">
							<label className="control-label fw-bold"> Item Description  </label>
							<input type="text" className="form-control form-control-sm" name="usage" 
							onChange={handleChange}
							/>
							
						</div>
                        {/* <div className="form-group">
							<label className="control-label fw-bold"> Dosage   </label>
							<input type="text" className="form-control form-control-sm" name="dosage"
                            placeholder='prescription for children and adults etc.'
                            onChange={handleChange}
							/>
							
						</div> */}
                        <div className="form-group">
							<label className="label control-label fw-bold">Store location ID </label>
							<input type="text" className="form-control form-control-sm" name="storeLocationID" 
							placeholder='optional'
							onChange={handleChange}
							/>
						</div>
						<div className="form-group">
							<label className="label control-label fw-bold">Expiration Date</label>
							<input type="date" className="form-control form-control-sm" name="expirationDate" 
							placeholder='optional'
							onChange={handleChange}
							/>
							<small id="pass_match" data-status=''></small>
						</div>
                        <div className="form-group">
							<label for="" className="control-label fw-bold">Item Category</label>
							<Form.Select size='sm' name="category" id="category" className="form-control"
							onChange={handleChange}
							>
								<option >Select Item Category... </option>
								{
									categories.map((cat) => (
										<option value={cat.id} >{cat.cat} </option>
									))
								}
							</Form.Select>
							<small><i></i></small>
						</div>
					</div>
				</div>
				<hr />
				<div className='row justify-content-center'>
                    <div className="col-lg-6 col-md-6 col-sm-8 col-xs-10 col-xxs-12 text-right d-flex justify-content-center ">
                        {/* <input type='submit' className="btn btn-color mr-2" id="submit-btn"
                        
                        value="Save" /> */}
                        <button type='button' className="btn btn-danger btn-lg" 
						onClick={() => navigate('/dashboard')}
						>
                            Cancel
                        </button>
                        <button
						type='submit'
						className="btn btn-color ms-2">
                            Save
                        </button> 
                        
                    </div>
                </div>
			</form>
		</div>
	</div>
    </div>
  )
}

export default AddStoreItem