import Axios from "./axios"
import {store} from '../redux/store'
import Cookie from 'react-cookies';
import axios from "axios";
// import { userData } from "../redux/slices/userSlice";
// import { transactionData } from "../redux/slices/userSlice";
// import { paymentData } from "../redux/slices/paymentSlice";

// import { userList as users } from "../redux/slices/userListSlice";
// import { orders } from "../redux/slices/ordersSlice";
// import { transactions } from "../redux/slices/transactionSlice";
// import { todayTransactions } from "../redux/slices/todayTransactionsSlice";
// import { dailyReviews } from "../redux/slices/dailyReviewSlice";
// import {allTimeReviews} from "../redux/slices/allTimeReviews";
import {storeItems} from "../redux/slices/storeItems";
// import { notifications } from "../redux/slices/notificationSlice";


export const contactUs = async (data) => {
    try {
        window.start_load();
        const res = await axios.post('https://uxtech.com.ng/abaafashab/api?contactUs', data);
        console.log(res.data);
        return res.data
    } catch (err) {
        console.log(err);
        return err;
    }finally{
        window.stop_load();
    }
}
export const login = async (data) => {
    // try{
    //     window.start_load();
    //     const res = await Axios.post('/api?login', data);
    //     if(res.data.success) {
    //         store.dispatch(userData(res.data.data));
    //         Cookie.save('token', res.data.token);
    //         return {
    //             success: res.data.success,
    //             message: res.data.message
    //         }
        
    //     }else{
    //         return res.data;
    //     }
    // }catch(err){
    //     return err.response.data
    // }finally{
    //     window.stop_load();
    // }

}

// Store Management
export const getStoreItems = async () => {
    try{
        window.start_load()
        const res = await Axios.get('/api?getStoreItems', {
            headers: {
                "Authorization": "Bearer "+Cookie.load('token')
            }
        });
        console.log(res.data);
        store.dispatch(storeItems(res.data.data));
        // Cookie.save('store', JSON.stringify(res.data.data));
        return res.data;
    }catch(err){
        console.log(err);
        return err.response.data;
    }finally{
        window.stop_load()
    }
}

// Order Management
export const saveOrder = async () => {
    try{
        window.start_load()
        const res = await Axios.get('/api?saveOrder', {
            headers: {
                "Authorization": "Bearer "+Cookie.load('token')
            }
        });
        console.log(res.data);
        return res.data;
    }catch(err){
        console.log(err);
        return err.response.data;
    }finally{
        window.stop_load()
    }
}

   

