import { usePagination, useGlobalFilter, useTable } from "react-table"
import { useEffect, useMemo } from "react"
import {Table, Button} from 'react-bootstrap';
import { useNavigate } from "react-router-dom";





const MyTable = ({data, columns, pageName, button, date}) => {
    const navigate = useNavigate();
  
    data = useMemo(() => data, []);
    const {
        getTabelProps,
        getTableBodyProps,
        headerGroups,
        page,
        pageOptions,
        setPageSize,
        prepareRow,
        setGlobalFilter,
        previousPage, nextPage, canNextPage, canPreviousPage, state
        
    } = useTable(
        {columns, data},
        useGlobalFilter,
        usePagination,
    );


    useEffect(() => {
        setPageSize(10)
    }, [])
    return (
        <div className=' row justify-content-center py-5'>
             <div className="d-flex justify-content-between mb-1 px-4">
                <h4> <b className=" ">{pageName}</b> </h4>
                
             </div>
            <div
            className="col-lg-11 col-md-11 col-sm-11 col-xs-11 col-xxs-11 card pb-4 px-3 pt-1"
            > 
           
            <div className="row">
                <div className="col-12 d-flex justify-content-end mb-3">
                {button && button}
                {date && date}
                </div>
                <div className="col-lg-3 col-6 col-md-3 me-auto">
                    <input name="search" type="text" placeHolder="Search..." className="form-control mb-sm-1"
                    onChange={e => {
                        // console.log(e.target.value);
                        setGlobalFilter(e.target.value)
                    }}
                    />
                </div>
                <div className="col-lg-3 col-6 col-md-3">
                    <select 
                    onChange={(e)=> setPageSize(Number(e.target.value))}
                    className="form-select">
                        <option value={10}>Show 10</option>
                        <option value={20}>Show 20</option>
                        <option value={30}>Show 30</option>
                        <option value={40}>Show 40</option>
                    </select>
                </div>
            </div>
            <hr />
            <Table  {... getTabelProps} 
            className=''
            bordered
            responsive
            variant="outline-dark"
            >
                <thead> 
                    {
                        headerGroups.map(headerGroup => (
                            <tr {... headerGroup.getHeaderGroupProps}>
                                {
                                    headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps}>
                                            {column.render('Header')}
                                        </th>
                                    ))
                                }
                            </tr>
                        ))
                    }
                </thead>
                <tbody {... getTableBodyProps}>

                    {
                        page.map(row => {
                            prepareRow(row)

                            return (
                                <tr {... row.getRowProps}>
                                    {
                                        row.cells.map(cell => (
                                            <td {...cell.getCellProps}>
                                                {
                                                    cell.render('Cell')
                                                }
                                            </td>
                                        ))
                                    }
                                </tr>
                            )
                        })
                    }
                    
                </tbody>
            </Table>
            <div style={{ marginBottom: '40px' }}>
                <center> 
                <Button disabled={!canPreviousPage}onClick={() => previousPage()} variant='dark' style={{ width: '100px' }} 
                className='btn-color'
                > Previous </Button>
                <span>
                        Page {state.pageIndex.length}
                       <strong> {state.pageIndex + 1} of {pageOptions.length} </strong>
                    </span>
                <Button disabled={!canNextPage} onClick={() => nextPage()} style={{ width: '100px' }} 
                className='btn-color'
                > Next </Button>
                </center>
                </div>
            </div>

        </div>
    )
}

export default MyTable