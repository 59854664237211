import React from 'react'
import { useSelector } from 'react-redux';
const Services = () => {
    const services = useSelector(state => state.services.payload);
    
  return (
    <div className='row justify-content-center py-5 section'
    id='services'
    // onPointerEnter={() => console.log('Services is on focus')}
    // onWheel={() => console.log('Services is on play')}
    
    >
         <p className='small-header text-center mt-2'> OUR SERVICES </p>
            {
              services.map((service, i) => {
                
                return (
                  <div className='col-xl-4 col-lg-4 col-md-4 col-12 news'
                  style={{ 
                    height: '250px'
                   }}
                  >
                      <div className='d-flex justify-content-center text-center align-items-center' style={{ 
                        
                        color: 'white',
                        padding: '5px'
                        }}>
                          <span className='text-center bg-primary d-flex justify-content-center text-center align-items-center'
                          style={{ 
                            borderRadius:'100%',
                        width: '120px',
                        height: '120px',
                        backgroundColor: 'navy',
                           }}
                          > 
                          {/* {service.icon} */}
                          <img src={service.icon} width={120} height={120} 
                          style={{ 
                            borderRadius: '100%'
                           }}
                          />
                          </span>
                      </div>
                      <p className='news-title text-muted text-center'> {service.title} </p>
                      <p className='text-muted text-center'> {service.description} </p>
                      {/* <Link
                      to={`/view-news?${category.news[0].id}`}
                      style={{ 
                        textDecoration: 'none'
                       }}
                      > */}
                        {/* <div className='news h-75'>
                          <img src={typeof(category.news[0].img) == 'object' ? category.news[0].img[0] : category.news[0].img} alt='Image'
                          height={250}
                          width={'100%'}
                          className=''
                          />
                          <p className='news-title'> {category.news[0].title.slice(0, 50)} {category.news[0].title.length > 50 && '...'}  </p>
                          <p className='news-body'> {category.news[0].body.slice(0, 100)}... </p>
                        </div> */}
                      {/* </Link> */}
                      {/* <span className='d-flex justify-content-end'>
                        <Button
                        // onClick={() => navigate(`/news?${category.name}`)}
                        className='more-btn'>
                            More ...
                        </Button>
                      </span> */}
                      
                  </div>
                )
              } )
            }
            
         </div>
  )
}

export default Services