


// const dReviews = store.getState(dailyReviews.pay);
export const dailyReviews = [
    {
        title: 'Orders',
        // 'amount': dReviews.transactions && dReviews.transactions,
        'icon': 'trans'
    },
    {
        title: 'Transactions',
        // 'amount': dReviews.transactions && dReviews.transactions,
        'icon': 'sales'
    },
    {
        title: 'Reports',
        // 'amount': '₦'+dReviews.salesAmount && dReviews.salesAmount, 
        'icon': 'sales'
    },
    {
        title: 'Payments',
        'amount': '₦12,000', 
        'icon': 'sProfit'
    },
    {
        title: 'Last Notification',
        'message': "Paracetamol is running out.",
        'icon': 'notification'
    },

];

export const overallReviews = [
    
    {
        title: 'Users',
        'amount': 12,
        icon: 'users' 
    },
    {
        title: 'Total Sales',
        'amount': '₦13,000',
        icon: 'transaction'
    },
    {
        title: 'Total Returned Amount',
        'amount': '₦103,000,000',
        icon: 'sales' 
    },
    {
        title: 'Total Profit',
        'amount': '₦1,200,000', 
        icon: 'profit'
    },
    {
        title: 'Total Store Items',
        'amount': '₦120,000', 
        icon: 'store'
    },
];
