

export const categories = [
  {
    id: 1,
    cat: 'Cereals'
  },
  {
    id: 2,
    cat: 'Vegetables'
  },
  {
    id: 3,
    cat: 'Smart Phone'
  },
  {
    id: 7,
    cat: 'others'
  }
]
export const data = [
      {
        "id": "2",
        "name": "Carbidopa 25 MG / Levodopa 100 MG Oral Tablet",
        "cardPrice": 500,
        "parkPrice": 4000
      },
      {
        "id": "3",
        "name": "Carbidopa 25 MG / Levodopa 250 MG Oral Tablet",
        "cardPrice": 500,
        "parkPrice": 4000
      },
      {
        "id": "4",
        "name": "Carisoprodol 350 MG Oral Tablet",
        "cardPrice": 500,
        "parkPrice": 4000
      },
      {
        "id": "5",
        "name": "Aspirin 325 MG / Carisoprodol 200 MG Oral Tablet",
        "cardPrice": 500,
        "parkPrice": 4000
      },
      {
        "id": "6",
        "name": "Levocarnitine 330 MG Oral Tablet",
        "cardPrice": 500,
        "parkPrice": 4000
      },
      {
        "id": "7",
        "name": "Cefaclor 500 MG Oral Capsule",
        "cardPrice": 500,
        "parkPrice": 4000
      },
]
export const store = [
    {
      "id": "1",
      "name": "drug_name"
    },
    {
      "id": "2",
      "name": "Carbidopa 25 MG / Levodopa 100 MG Oral Tablet"
    },
    {
      "id": "3",
      "name": "Carbidopa 25 MG / Levodopa 250 MG Oral Tablet"
    },
    {
      "id": "4",
      "name": "Carisoprodol 350 MG Oral Tablet"
    },
    {
      "id": "5",
      "name": "Aspirin 325 MG / Carisoprodol 200 MG Oral Tablet"
    },
    {
      "id": "6",
      "name": "Levocarnitine 330 MG Oral Tablet"
    },
    {
      "id": "7",
      "name": "Cefaclor 500 MG Oral Capsule"
    },
    {
      "id": "8",
      "name": "cefprozil 250 MG Oral Tablet"
    },
    {
      "id": "9",
      "name": "Clorazepate Dipotassium 3.75 MG Oral Tablet"
    },
    {
      "id": "10",
      "name": "Clorazepate Dipotassium 7.5 MG Oral Tablet"
    },
    {
      "id": "11",
      "name": "Chlorothiazide 250 MG Oral Tablet"
    },
    {
      "id": "12",
      "name": "Chlorothiazide 500 MG Oral Tablet"
    },
    {
      "id": "13",
      "name": "Chlorpropamide 100 MG Oral Tablet"
    },
    {
      "id": "14",
      "name": "Chlorpropamide 250 MG Oral Tablet"
    },
    {
      "id": "15",
      "name": "Chlorthalidone 25 MG Oral Tablet"
    },
    {
      "id": "16",
      "name": "Chlorthalidone 50 MG Oral Tablet"
    },
    {
      "id": "17",
      "name": "Chlorzoxazone 500 MG Oral Tablet"
    },
    {
      "id": "18",
      "name": "Cimetidine 200 MG Oral Tablet"
    },
    {
      "id": "19",
      "name": "Cimetidine 300 MG Oral Tablet"
    },
    {
      "id": "20",
      "name": "Cimetidine 400 MG Oral Tablet"
    },
    {
      "id": "21",
      "name": "Cimetidine 800 MG Oral Tablet"
    },
    {
      "id": "22",
      "name": "Ciprofloxacin 250 MG Oral Tablet"
    },
    {
      "id": "23",
      "name": "Ciprofloxacin 750 MG Oral Tablet"
    },
    {
      "id": "24",
      "name": "Clarithromycin 250 MG Oral Tablet"
    },
    {
      "id": "25",
      "name": "Clarithromycin 500 MG Oral Tablet"
    },
    {
      "id": "26",
      "name": "Clindamycin 150 MG Oral Capsule"
    },
    {
      "id": "27",
      "name": "Clindamycin 10 MG/ML Topical Lotion"
    },
    {
      "id": "28",
      "name": "clocortolone 1 MG/ML Topical Cream"
    },
    {
      "id": "29",
      "name": "Clonazepam 0.5 MG Oral Tablet"
    },
    {
      "id": "30",
      "name": "Clonazepam 1 MG Oral Tablet"
    },
    {
      "id": "31",
      "name": "Clonazepam 2 MG Oral Tablet"
    },
    {
      "id": "32",
      "name": "Carbidopa 10 MG / Levodopa 100 MG Oral Tablet"
    },
    {
      "id": "33",
      "name": "Captopril 50 MG / Hydrochlorothiazide 25 MG Oral Tablet"
    },
    {
      "id": "34",
      "name": "Captopril 50 MG / Hydrochlorothiazide 15 MG Oral Tablet"
    },
    {
      "id": "35",
      "name": "Captopril 25 MG / Hydrochlorothiazide 25 MG Oral Tablet"
    },
    {
      "id": "36",
      "name": "Captopril 25 MG / Hydrochlorothiazide 15 MG Oral Tablet"
    },
    {
      "id": "37",
      "name": "calcium acetate 667 MG Oral Tablet"
    },
    {
      "id": "38",
      "name": "Acetaminophen 325 MG / butalbital 50 MG Oral Tablet"
    },
    {
      "id": "39",
      "name": "Bumetanide 2 MG Oral Tablet"
    },
    {
      "id": "40",
      "name": "Bumetanide 1 MG Oral Tablet"
    },
    {
      "id": "41",
      "name": "Bumetanide 0.5 MG Oral Tablet"
    },
    {
      "id": "42",
      "name": "Bromocriptine 5 MG Oral Capsule"
    },
    {
      "id": "43",
      "name": "Bromocriptine 2.5 MG Oral Tablet"
    },
    {
      "id": "44",
      "name": "Betamethasone 0.001 MG/MG Topical Ointment"
    },
    {
      "id": "45",
      "name": "Betamethasone 1 MG/ML Topical Lotion"
    },
    {
      "id": "46",
      "name": "Betamethasone 1 MG/ML Topical Cream"
    },
    {
      "id": "47",
      "name": "Betamethasone 0.0005 MG/MG Topical Ointment"
    },
    {
      "id": "48",
      "name": "benzonatate 100 MG Oral Capsule"
    },
    {
      "id": "49",
      "name": "Baclofen 20 MG Oral Tablet"
    },
    {
      "id": "50",
      "name": "Baclofen 10 MG Oral Tablet"
    },
    {
      "id": "51",
      "name": "Azathioprine 50 MG Oral Tablet"
    },
    {
      "id": "52",
      "name": "Atenolol 50 MG / Chlorthalidone 25 MG Oral Tablet"
    },
    {
      "id": "53",
      "name": "Atenolol 100 MG / Chlorthalidone 25 MG Oral Tablet"
    },
    {
      "id": "54",
      "name": "Atenolol 50 MG Oral Tablet"
    },
    {
      "id": "55",
      "name": "Atenolol 25 MG Oral Tablet"
    },
    {
      "id": "56",
      "name": "Atenolol 100 MG Oral Tablet"
    },
    {
      "id": "57",
      "name": "Amoxapine 50 MG Oral Tablet"
    },
    {
      "id": "58",
      "name": "Amoxapine 25 MG Oral Tablet"
    },
    {
      "id": "59",
      "name": "Amoxapine 150 MG Oral Tablet"
    },
    {
      "id": "60",
      "name": "Amoxapine 100 MG Oral Tablet"
    },
    {
      "id": "61",
      "name": "ammonium lactate 120 MG/ML Topical Lotion"
    },
    {
      "id": "62",
      "name": "Amlodipine 5 MG Oral Tablet"
    },
    {
      "id": "63",
      "name": "amcinonide 0.001 MG/MG Topical Ointment"
    },
    {
      "id": "64",
      "name": "amcinonide 1 MG/ML Topical Cream"
    },
    {
      "id": "65",
      "name": "Alprazolam 2 MG Oral Tablet"
    },
    {
      "id": "66",
      "name": "Alprazolam 1 MG Oral Tablet"
    },
    {
      "id": "67",
      "name": "Allopurinol 300 MG Oral Tablet"
    },
    {
      "id": "68",
      "name": "Allopurinol 100 MG Oral Tablet"
    },
    {
      "id": "69",
      "name": "Albuterol 4 MG Oral Tablet"
    },
    {
      "id": "70",
      "name": "Albuterol 2 MG Oral Tablet"
    },
    {
      "id": "71",
      "name": "Acyclovir 800 MG Oral Tablet"
    },
    {
      "id": "72",
      "name": "Acyclovir 0.05 MG/MG Topical Ointment"
    },
    {
      "id": "73",
      "name": "Acyclovir 400 MG Oral Tablet"
    },
    {
      "id": "74",
      "name": "Acyclovir 200 MG Oral Capsule"
    },
    {
      "id": "75",
      "name": "Acetic Acid 20 MG/ML Otic Solution"
    },
    {
      "id": "76",
      "name": "Acetazolamide 250 MG Oral Tablet"
    },
    {
      "id": "77",
      "name": "Acetazolamide 125 MG Oral Tablet"
    },
    {
      "id": "78",
      "name": "candesartan cilexetil 8 MG Oral Tablet"
    },
    {
      "id": "79",
      "name": "candesartan cilexetil 4 MG Oral Tablet"
    },
    {
      "id": "80",
      "name": "Altretamine 50 MG Oral Capsule [Hexalen]"
    },
    {
      "id": "81",
      "name": "Interferon beta-1a 0.03 MG Injection [Avonex]"
    },
    {
      "id": "82",
      "name": "Indinavir 400 MG Oral Capsule [Crixivan]"
    },
    {
      "id": "83",
      "name": "Indinavir 200 MG Oral Capsule [Crixivan]"
    },
    {
      "id": "84",
      "name": "Glucagon 1 MG Injection [GlucaGen]"
    },
    {
      "id": "85",
      "name": "Ritonavir 80 MG/ML Oral Solution [Norvir]"
    },
    {
      "id": "86",
      "name": "Ritonavir 100 MG Oral Capsule [Norvir]"
    },
    {
      "id": "87",
      "name": "Lamivudine 10 MG/ML Oral Solution [Epivir]"
    },
    {
      "id": "88",
      "name": "Toremifene 60 MG Oral Tablet [Fareston]"
    },
    {
      "id": "89",
      "name": "Itraconazole 10 MG/ML Oral Solution [Sporanox]"
    },
    {
      "id": "90",
      "name": "meloxicam 15 MG Oral Tablet"
    },
    {
      "id": "91",
      "name": "topiramate 50 MG Oral Tablet"
    },
    {
      "id": "92",
      "name": "mometasone furoate 1 MG/ML Topical Lotion"
    },
    {
      "id": "93",
      "name": "mometasone furoate 0.001 MG/MG Topical Ointment"
    },
    {
      "id": "94",
      "name": "Estrogens, Conjugated (USP) 0.625 MG Oral Tablet [Premarin]"
    },
    {
      "id": "95",
      "name": "Metronidazole 0.0075 MG/MG Vaginal Gel"
    },
    {
      "id": "96",
      "name": "Azithromycin 40 MG/ML Oral Suspension"
    },
    {
      "id": "97",
      "name": "Haloperidol 2 MG/ML Oral Solution"
    },
    {
      "id": "98",
      "name": "Lindane 10 MG/ML Topical Lotion"
    },
    {
      "id": "99",
      "name": "Desoximetasone 0.0005 MG/MG Topical Ointment"
    },
    {
      "id": "100",
      "name": "Mupirocin 0.02 MG/MG Nasal Ointment [Bactroban]"
    },
    {
      "id": "101",
      "name": "ceftibuten 400 MG Oral Capsule [Cedax]"
    },
    {
      "id": "102",
      "name": "insulin human, isophane 70 UNT/ML / Regular Insulin, Human 30 UNT/ML Injectable Suspension [Humulin]"
    },
    {
      "id": "103",
      "name": "Permethrin 50 MG/ML Topical Cream"
    },
    {
      "id": "104",
      "name": "Silver Sulfadiazine 10 MG/ML Topical Cream"
    },
    {
      "id": "105",
      "name": "Mupirocin 0.02 MG/MG Topical Ointment"
    },
    {
      "id": "106",
      "name": "Ketoconazole 20 MG/ML Medicated Shampoo"
    },
    {
      "id": "107",
      "name": "Tretinoin 0.5 MG/ML Topical Cream"
    },
    {
      "id": "108",
      "name": "Tretinoin 0.25 MG/ML Topical Cream"
    },
    {
      "id": "109",
      "name": "Hydrocortisone 10 MG/ML Topical Cream"
    },
    {
      "id": "110",
      "name": "Thioguanine 40 MG Oral Tablet [Tabloid]"
    },
    {
      "id": "111",
      "name": "Methotrexate 2.5 MG Oral Tablet"
    },
    {
      "id": "112",
      "name": "Fluorouracil 50 MG/ML Topical Cream"
    },
    {
      "id": "113",
      "name": "Melphalan 2 MG Oral Tablet [Alkeran]"
    },
    {
      "id": "114",
      "name": "Chlorambucil 2 MG Oral Tablet [Leukeran]"
    },
    {
      "id": "115",
      "name": "isoniazid 10 MG/ML Oral Solution"
    },
    {
      "id": "116",
      "name": "Cefadroxil 100 MG/ML Oral Suspension"
    },
    {
      "id": "117",
      "name": "Ondansetron 4 MG Disintegrating Oral Tablet"
    },
    {
      "id": "118",
      "name": "nabilone 1 MG Oral Capsule [Cesamet]"
    },
    {
      "id": "119",
      "name": "Metoclopramide 1 MG/ML Oral Solution"
    },
    {
      "id": "120",
      "name": "Mepenzolate 25 MG Oral Tablet [Cantil]"
    },
    {
      "id": "121",
      "name": "lamotrigine 100 MG Disintegrating Oral Tablet"
    },
    {
      "id": "122",
      "name": "Fluocinonide 0.0005 MG/MG Topical Ointment"
    },
    {
      "id": "123",
      "name": "Fluocinonide 0.5 MG/ML Topical Cream"
    },
    {
      "id": "124",
      "name": "Hydrocortisone 0.025 MG/MG Topical Ointment"
    },
    {
      "id": "125",
      "name": "Primidone 250 MG Oral Tablet"
    },
    {
      "id": "126",
      "name": "Fluorouracil 10 MG/ML Topical Cream [Fluoroplex]"
    },
    {
      "id": "127",
      "name": "Oxymetholone 50 MG Oral Tablet [Anadrol-50]"
    },
    {
      "id": "128",
      "name": "Clozapine 100 MG Oral Tablet"
    },
    {
      "id": "129",
      "name": "Clozapine 25 MG Oral Tablet"
    },
    {
      "id": "130",
      "name": "Colchicine 0.6 MG Oral Tablet"
    },
    {
      "id": "131",
      "name": "Cyclosporine 25 MG Oral Capsule"
    },
    {
      "id": "132",
      "name": "Danazol 100 MG Oral Capsule"
    },
    {
      "id": "133",
      "name": "Danazol 200 MG Oral Capsule"
    },
    {
      "id": "134",
      "name": "Danazol 50 MG Oral Capsule"
    },
    {
      "id": "135",
      "name": "Dapsone 100 MG Oral Tablet"
    },
    {
      "id": "136",
      "name": "Dapsone 25 MG Oral Tablet"
    },
    {
      "id": "137",
      "name": "Desonide 0.5 MG/ML Topical Lotion"
    },
    {
      "id": "138",
      "name": "Desoximetasone 0.5 MG/ML Topical Cream"
    },
    {
      "id": "139",
      "name": "Desoximetasone 2.5 MG/ML Topical Cream"
    },
    {
      "id": "140",
      "name": "Desoximetasone 0.0025 MG/MG Topical Ointment"
    },
    {
      "id": "141",
      "name": "Dexamethasone 0.5 MG Oral Tablet"
    },
    {
      "id": "142",
      "name": "Dexamethasone 1 MG Oral Tablet"
    },
    {
      "id": "143",
      "name": "Dexamethasone 1.5 MG Oral Tablet"
    },
    {
      "id": "144",
      "name": "Dexamethasone 2 MG Oral Tablet"
    },
    {
      "id": "145",
      "name": "Dexamethasone 4 MG Oral Tablet"
    },
    {
      "id": "146",
      "name": "Dexamethasone 6 MG Oral Tablet"
    },
    {
      "id": "147",
      "name": "Diazepam 10 MG Oral Tablet"
    },
    {
      "id": "148",
      "name": "Diazepam 2 MG Oral Tablet"
    },
    {
      "id": "149",
      "name": "Diazepam 5 MG Oral Tablet"
    },
    {
      "id": "150",
      "name": "Dicloxacillin 250 MG Oral Capsule"
    },
    {
      "id": "151",
      "name": "Dicloxacillin 500 MG Oral Capsule"
    },
    {
      "id": "152",
      "name": "Diflunisal 500 MG Oral Tablet"
    },
    {
      "id": "153",
      "name": "Digoxin 0.125 MG Oral Tablet"
    },
    {
      "id": "154",
      "name": "Digoxin 0.25 MG Oral Tablet"
    },
    {
      "id": "155",
      "name": "Dipyridamole 50 MG Oral Tablet"
    },
    {
      "id": "156",
      "name": "Disulfiram 250 MG Oral Tablet"
    },
    {
      "id": "157",
      "name": "Disulfiram 500 MG Oral Tablet"
    },
    {
      "id": "158",
      "name": "Doxazosin 1 MG Oral Tablet"
    },
    {
      "id": "159",
      "name": "Doxazosin 2 MG Oral Tablet"
    },
    {
      "id": "160",
      "name": "Doxazosin 4 MG Oral Tablet"
    },
    {
      "id": "161",
      "name": "Doxazosin 8 MG Oral Tablet"
    },
    {
      "id": "162",
      "name": "Tetrahydrocannabinol 10 MG Oral Capsule"
    },
    {
      "id": "163",
      "name": "Tetrahydrocannabinol 2.5 MG Oral Capsule"
    },
    {
      "id": "164",
      "name": "Tetrahydrocannabinol 5 MG Oral Capsule"
    },
    {
      "id": "165",
      "name": "Erythromycin 500 MG Oral Tablet"
    },
    {
      "id": "166",
      "name": "Estazolam 1 MG Oral Tablet"
    },
    {
      "id": "167",
      "name": "Estazolam 2 MG Oral Tablet"
    },
    {
      "id": "168",
      "name": "Estradiol 0.5 MG Oral Tablet"
    },
    {
      "id": "169",
      "name": "Estradiol 1 MG Oral Tablet"
    },
    {
      "id": "170",
      "name": "Estradiol 2 MG Oral Tablet"
    },
    {
      "id": "171",
      "name": "Estrogens, Esterified (USP) 1.25 MG / Methyltestosterone 2.5 MG Oral Tablet"
    },
    {
      "id": "172",
      "name": "Ethosuximide 250 MG Oral Capsule"
    },
    {
      "id": "173",
      "name": "Etodolac 200 MG Oral Capsule"
    },
    {
      "id": "174",
      "name": "Etodolac 300 MG Oral Capsule"
    },
    {
      "id": "175",
      "name": "Etodolac 400 MG Oral Tablet"
    },
    {
      "id": "176",
      "name": "Etoposide 50 MG Oral Capsule"
    },
    {
      "id": "177",
      "name": "72 HR Fentanyl 0.075 MG/HR Transdermal Patch"
    },
    {
      "id": "178",
      "name": "Fluconazole 100 MG Oral Tablet"
    },
    {
      "id": "179",
      "name": "Fluconazole 150 MG Oral Tablet"
    },
    {
      "id": "180",
      "name": "Fluconazole 200 MG Oral Tablet"
    },
    {
      "id": "181",
      "name": "Fluconazole 50 MG Oral Tablet"
    },
    {
      "id": "182",
      "name": "Flucytosine 250 MG Oral Capsule"
    },
    {
      "id": "183",
      "name": "Flucytosine 500 MG Oral Capsule"
    },
    {
      "id": "184",
      "name": "Flurbiprofen 100 MG Oral Tablet"
    },
    {
      "id": "185",
      "name": "Flurbiprofen 50 MG Oral Tablet"
    },
    {
      "id": "186",
      "name": "Flutamide 125 MG Oral Capsule"
    },
    {
      "id": "187",
      "name": "Furosemide 10 MG/ML Oral Solution"
    },
    {
      "id": "188",
      "name": "Furosemide 8 MG/ML Oral Solution"
    },
    {
      "id": "189",
      "name": "Furosemide 80 MG Oral Tablet"
    },
    {
      "id": "190",
      "name": "Gentamicin Sulfate (USP) 1 MG/ML Topical Cream"
    },
    {
      "id": "191",
      "name": "Glyburide 1.25 MG Oral Tablet"
    },
    {
      "id": "192",
      "name": "Glycopyrrolate 1 MG Oral Tablet"
    },
    {
      "id": "193",
      "name": "Glycopyrrolate 2 MG Oral Tablet"
    },
    {
      "id": "194",
      "name": "Guanfacine 1 MG Oral Tablet"
    },
    {
      "id": "195",
      "name": "Guanfacine 2 MG Oral Tablet"
    },
    {
      "id": "196",
      "name": "Haloperidol 20 MG Oral Tablet"
    },
    {
      "id": "197",
      "name": "Hydrochlorothiazide 50 MG Oral Tablet"
    },
    {
      "id": "198",
      "name": "Hydrocortisone 10 MG Oral Tablet"
    },
    {
      "id": "199",
      "name": "Hydrocortisone 20 MG Oral Tablet"
    },
    {
      "id": "200",
      "name": "Hydrocortisone 25 MG/ML Topical Lotion"
    },
    {
      "id": "201",
      "name": "Hydrocortisone 5 MG Oral Tablet"
    },
    {
      "id": "202",
      "name": "hydroxyurea 500 MG Oral Capsule"
    },
    {
      "id": "203",
      "name": "Ibuprofen 400 MG Oral Tablet"
    },
    {
      "id": "204",
      "name": "Ibuprofen 600 MG Oral Tablet"
    },
    {
      "id": "205",
      "name": "Ibuprofen 800 MG Oral Tablet"
    },
    {
      "id": "206",
      "name": "Indapamide 1.25 MG Oral Tablet"
    },
    {
      "id": "207",
      "name": "Indapamide 2.5 MG Oral Tablet"
    },
    {
      "id": "208",
      "name": "Indomethacin 25 MG Oral Capsule"
    },
    {
      "id": "209",
      "name": "Indomethacin 50 MG Oral Capsule"
    },
    {
      "id": "210",
      "name": "isoniazid 300 MG Oral Tablet"
    },
    {
      "id": "211",
      "name": "Isosorbide Dinitrate 30 MG Oral Tablet"
    },
    {
      "id": "212",
      "name": "Isradipine 2.5 MG Oral Capsule"
    },
    {
      "id": "213",
      "name": "Isradipine 5 MG Oral Capsule"
    },
    {
      "id": "214",
      "name": "Ketoconazole 200 MG Oral Tablet"
    },
    {
      "id": "215",
      "name": "Ketoprofen 50 MG Oral Capsule"
    },
    {
      "id": "216",
      "name": "Ketoprofen 75 MG Oral Capsule"
    },
    {
      "id": "217",
      "name": "Leucovorin 10 MG Oral Tablet"
    },
    {
      "id": "218",
      "name": "Leucovorin 15 MG Oral Tablet"
    },
    {
      "id": "219",
      "name": "Leucovorin 25 MG Oral Tablet"
    },
    {
      "id": "220",
      "name": "Leucovorin 5 MG Oral Tablet"
    },
    {
      "id": "221",
      "name": "Levorphanol 2 MG Oral Tablet"
    },
    {
      "id": "222",
      "name": "Lidocaine 25 MG/ML / Prilocaine 25 MG/ML Topical Cream"
    },
    {
      "id": "223",
      "name": "Lindane 10 MG/ML Medicated Shampoo"
    },
    {
      "id": "224",
      "name": "Lisinopril 40 MG Oral Tablet"
    },
    {
      "id": "225",
      "name": "Hydrochlorothiazide 12.5 MG / Lisinopril 10 MG Oral Tablet"
    },
    {
      "id": "226",
      "name": "Hydrochlorothiazide 12.5 MG / Lisinopril 20 MG Oral Tablet"
    },
    {
      "id": "227",
      "name": "Hydrochlorothiazide 25 MG / Lisinopril 20 MG Oral Tablet"
    },
    {
      "id": "228",
      "name": "Lithium Carbonate 300 MG Oral Capsule"
    },
    {
      "id": "229",
      "name": "Lithium Carbonate 300 MG Oral Tablet"
    },
    {
      "id": "230",
      "name": "Lithium Carbonate 300 MG Extended Release Oral Tablet"
    },
    {
      "id": "231",
      "name": "Lithium Carbonate 450 MG Extended Release Oral Tablet"
    },
    {
      "id": "232",
      "name": "Lithium Carbonate 600 MG Oral Capsule"
    },
    {
      "id": "233",
      "name": "Lorazepam 0.5 MG Oral Tablet"
    },
    {
      "id": "234",
      "name": "Lorazepam 1 MG Oral Tablet"
    },
    {
      "id": "235",
      "name": "Lorazepam 2 MG Oral Tablet"
    },
    {
      "id": "236",
      "name": "Lovastatin 10 MG Oral Tablet"
    },
    {
      "id": "237",
      "name": "Lovastatin 20 MG Oral Tablet"
    },
    {
      "id": "238",
      "name": "Lovastatin 40 MG Oral Tablet"
    },
    {
      "id": "239",
      "name": "Meprobamate 200 MG Oral Tablet"
    },
    {
      "id": "240",
      "name": "Meprobamate 400 MG Oral Tablet"
    },
    {
      "id": "241",
      "name": "mercaptopurine 50 MG Oral Tablet"
    },
    {
      "id": "242",
      "name": "metaxalone 400 MG Oral Tablet"
    },
    {
      "id": "243",
      "name": "Methazolamide 25 MG Oral Tablet"
    },
    {
      "id": "244",
      "name": "Methazolamide 50 MG Oral Tablet"
    },
    {
      "id": "245",
      "name": "Methimazole 10 MG Oral Tablet"
    },
    {
      "id": "246",
      "name": "Methimazole 5 MG Oral Tablet"
    },
    {
      "id": "247",
      "name": "Methocarbamol 500 MG Oral Tablet"
    },
    {
      "id": "248",
      "name": "Methocarbamol 750 MG Oral Tablet"
    },
    {
      "id": "249",
      "name": "Methoxsalen 10 MG Oral Capsule"
    },
    {
      "id": "250",
      "name": "Methyclothiazide 5 MG Oral Tablet"
    },
    {
      "id": "251",
      "name": "Methyldopa 250 MG Oral Tablet"
    },
    {
      "id": "252",
      "name": "Methyldopa 500 MG Oral Tablet"
    },
    {
      "id": "253",
      "name": "Hydrochlorothiazide 25 MG / Methyldopa 250 MG Oral Tablet"
    },
    {
      "id": "254",
      "name": "Hydrochlorothiazide 15 MG / Methyldopa 250 MG Oral Tablet"
    },
    {
      "id": "255",
      "name": "Methylprednisolone 32 MG Oral Tablet"
    },
    {
      "id": "256",
      "name": "Methylprednisolone 8 MG Oral Tablet"
    },
    {
      "id": "257",
      "name": "Methyltestosterone 10 MG Oral Capsule"
    },
    {
      "id": "258",
      "name": "Metolazone 10 MG Oral Tablet"
    },
    {
      "id": "259",
      "name": "Metolazone 2.5 MG Oral Tablet"
    },
    {
      "id": "260",
      "name": "Minocycline 100 MG Oral Capsule"
    },
    {
      "id": "261",
      "name": "Minocycline 50 MG Oral Capsule"
    },
    {
      "id": "262",
      "name": "Minoxidil 10 MG Oral Tablet"
    },
    {
      "id": "263",
      "name": "Minoxidil 2.5 MG Oral Tablet"
    },
    {
      "id": "264",
      "name": "Bendroflumethiazide 5 MG / Nadolol 40 MG Oral Tablet"
    },
    {
      "id": "265",
      "name": "Bendroflumethiazide 5 MG / Nadolol 80 MG Oral Tablet"
    },
    {
      "id": "266",
      "name": "Nadolol 20 MG Oral Tablet"
    },
    {
      "id": "267",
      "name": "Nadolol 40 MG Oral Tablet"
    },
    {
      "id": "268",
      "name": "Nadolol 80 MG Oral Tablet"
    },
    {
      "id": "269",
      "name": "Naproxen 375 MG Oral Tablet"
    },
    {
      "id": "270",
      "name": "Naproxen 250 MG Oral Tablet"
    },
    {
      "id": "271",
      "name": "Naproxen 500 MG Oral Tablet"
    },
    {
      "id": "272",
      "name": "24 HR Nicotine 0.583 MG/HR Transdermal Patch"
    },
    {
      "id": "273",
      "name": "24 HR Nicotine 0.875 MG/HR Transdermal Patch"
    },
    {
      "id": "274",
      "name": "24 HR Nicotine 0.292 MG/HR Transdermal Patch"
    },
    {
      "id": "275",
      "name": "Nifedipine 10 MG Oral Capsule"
    },
    {
      "id": "276",
      "name": "Nifedipine 20 MG Oral Capsule"
    },
    {
      "id": "277",
      "name": "24 HR Nifedipine 30 MG Extended Release Oral Tablet"
    },
    {
      "id": "278",
      "name": "24 HR Nifedipine 60 MG Extended Release Oral Tablet"
    },
    {
      "id": "279",
      "name": "24 HR Nifedipine 90 MG Extended Release Oral Tablet"
    },
    {
      "id": "280",
      "name": "Nimodipine 30 MG Oral Capsule"
    },
    {
      "id": "281",
      "name": "Nitroglycerin 0.4 MG Sublingual Tablet"
    },
    {
      "id": "282",
      "name": "Nizatidine 300 MG Oral Capsule"
    },
    {
      "id": "283",
      "name": "Nortriptyline 10 MG Oral Capsule"
    },
    {
      "id": "284",
      "name": "Nortriptyline 50 MG Oral Capsule"
    },
    {
      "id": "285",
      "name": "Nortriptyline 75 MG Oral Capsule"
    },
    {
      "id": "286",
      "name": "Ofloxacin 400 MG Oral Tablet"
    },
    {
      "id": "287",
      "name": "Omeprazole 20 MG Delayed Release Oral Capsule"
    },
    {
      "id": "288",
      "name": "Ondansetron 4 MG Oral Tablet"
    },
    {
      "id": "289",
      "name": "Oxandrolone 2.5 MG Oral Tablet"
    },
    {
      "id": "290",
      "name": "Oxazepam 10 MG Oral Capsule"
    },
    {
      "id": "291",
      "name": "Oxazepam 30 MG Oral Capsule"
    },
    {
      "id": "292",
      "name": "Perphenazine 16 MG Oral Tablet"
    },
    {
      "id": "293",
      "name": "Perphenazine 2 MG Oral Tablet"
    },
    {
      "id": "294",
      "name": "Perphenazine 4 MG Oral Tablet"
    },
    {
      "id": "295",
      "name": "Perphenazine 8 MG Oral Tablet"
    },
    {
      "id": "296",
      "name": "Phenobarbital 100 MG Oral Tablet"
    },
    {
      "id": "297",
      "name": "Phenobarbital 16.2 MG Oral Tablet"
    },
    {
      "id": "298",
      "name": "Phenobarbital 60 MG Oral Tablet"
    },
    {
      "id": "299",
      "name": "Pimozide 2 MG Oral Tablet"
    },
    {
      "id": "300",
      "name": "Pindolol 10 MG Oral Tablet"
    },
    {
      "id": "301",
      "name": "Pindolol 5 MG Oral Tablet"
    },
    {
      "id": "302",
      "name": "Piroxicam 10 MG Oral Capsule"
    },
    {
      "id": "303",
      "name": "Piroxicam 20 MG Oral Capsule"
    },
    {
      "id": "304",
      "name": "Potassium Chloride 20 MEQ Extended Release Oral Tablet"
    },
    {
      "id": "305",
      "name": "Prazosin 5 MG Oral Capsule"
    },
    {
      "id": "306",
      "name": "Prednisone 1 MG Oral Tablet"
    },
    {
      "id": "307",
      "name": "Prednisone 2.5 MG Oral Tablet"
    },
    {
      "id": "308",
      "name": "Prednisone 50 MG Oral Tablet"
    },
    {
      "id": "309",
      "name": "Primidone 50 MG Oral Tablet"
    },
    {
      "id": "310",
      "name": "Probenecid 500 MG Oral Tablet"
    },
    {
      "id": "311",
      "name": "Colchicine 0.5 MG / Probenecid 500 MG Oral Tablet"
    },
    {
      "id": "312",
      "name": "Prochlorperazine 25 MG Rectal Suppository"
    },
    {
      "id": "313",
      "name": "Propantheline 15 MG Oral Tablet"
    },
    {
      "id": "314",
      "name": "Propylthiouracil 50 MG Oral Tablet"
    },
    {
      "id": "315",
      "name": "Pyrazinamide 500 MG Oral Tablet"
    },
    {
      "id": "316",
      "name": "Ramipril 2.5 MG Oral Capsule"
    },
    {
      "id": "317",
      "name": "Ramipril 5 MG Oral Capsule"
    },
    {
      "id": "318",
      "name": "Ranitidine 150 MG Oral Capsule"
    },
    {
      "id": "319",
      "name": "Ranitidine 150 MG Oral Tablet"
    },
    {
      "id": "320",
      "name": "Ranitidine 300 MG Oral Capsule"
    },
    {
      "id": "321",
      "name": "Ranitidine 300 MG Oral Tablet"
    },
    {
      "id": "322",
      "name": "Reserpine 0.1 MG Oral Tablet"
    },
    {
      "id": "323",
      "name": "Reserpine 0.25 MG Oral Tablet"
    },
    {
      "id": "324",
      "name": "Rifabutin 150 MG Oral Capsule"
    },
    {
      "id": "325",
      "name": "Rifampin 150 MG Oral Capsule"
    },
    {
      "id": "326",
      "name": "Rifampin 300 MG Oral Capsule"
    },
    {
      "id": "327",
      "name": "Simvastatin 40 MG Oral Tablet"
    },
    {
      "id": "328",
      "name": "Sodium Fluoride 0.55 MG Chewable Tablet"
    },
    {
      "id": "329",
      "name": "Spironolactone 100 MG Oral Tablet"
    },
    {
      "id": "330",
      "name": "Spironolactone 50 MG Oral Tablet"
    },
    {
      "id": "331",
      "name": "Hydrochlorothiazide 25 MG / Spironolactone 25 MG Oral Tablet"
    },
    {
      "id": "332",
      "name": "Sulfadiazine 500 MG Oral Tablet"
    },
    {
      "id": "333",
      "name": "Sulfasalazine 500 MG Oral Tablet"
    },
    {
      "id": "334",
      "name": "Sulindac 150 MG Oral Tablet"
    },
    {
      "id": "335",
      "name": "Sulindac 200 MG Oral Tablet"
    },
    {
      "id": "336",
      "name": "Tamoxifen 10 MG Oral Tablet"
    },
    {
      "id": "337",
      "name": "Temazepam 15 MG Oral Capsule"
    },
    {
      "id": "338",
      "name": "Temazepam 30 MG Oral Capsule"
    },
    {
      "id": "339",
      "name": "Temazepam 7.5 MG Oral Capsule"
    },
    {
      "id": "340",
      "name": "terconazole 80 MG Vaginal Suppository"
    },
    {
      "id": "341",
      "name": "Tetracycline 250 MG Oral Capsule"
    },
    {
      "id": "342",
      "name": "Tetracycline 500 MG Oral Capsule"
    },
    {
      "id": "343",
      "name": "Thioridazine 100 MG Oral Tablet"
    },
    {
      "id": "344",
      "name": "Thioridazine 25 MG Oral Tablet"
    },
    {
      "id": "345",
      "name": "Thioridazine 50 MG Oral Tablet"
    },
    {
      "id": "346",
      "name": "Timolol 2.5 MG/ML Ophthalmic Solution"
    },
    {
      "id": "347",
      "name": "Timolol 10 MG Oral Tablet"
    },
    {
      "id": "348",
      "name": "Timolol 20 MG Oral Tablet"
    },
    {
      "id": "349",
      "name": "Timolol 5 MG Oral Tablet"
    },
    {
      "id": "350",
      "name": "Tolazamide 250 MG Oral Tablet"
    },
    {
      "id": "351",
      "name": "Tolazamide 500 MG Oral Tablet"
    },
    {
      "id": "352",
      "name": "Tolbutamide 500 MG Oral Tablet"
    },
    {
      "id": "353",
      "name": "Tolmetin 200 MG Oral Tablet"
    },
    {
      "id": "354",
      "name": "Tolmetin 400 MG Oral Capsule"
    },
    {
      "id": "355",
      "name": "Tolmetin 600 MG Oral Tablet"
    },
    {
      "id": "356",
      "name": "Tretinoin 1 MG/ML Topical Cream"
    },
    {
      "id": "357",
      "name": "Hydrochlorothiazide 25 MG / Triamterene 50 MG Oral Capsule"
    },
    {
      "id": "358",
      "name": "Hydrochlorothiazide 25 MG / Triamterene 37.5 MG Oral Capsule"
    },
    {
      "id": "359",
      "name": "Triazolam 0.125 MG Oral Tablet"
    },
    {
      "id": "360",
      "name": "Triazolam 0.25 MG Oral Tablet"
    },
    {
      "id": "361",
      "name": "Trifluoperazine 1 MG Oral Tablet"
    },
    {
      "id": "362",
      "name": "Trifluoperazine 10 MG Oral Tablet"
    },
    {
      "id": "363",
      "name": "Trifluoperazine 2 MG Oral Tablet"
    },
    {
      "id": "364",
      "name": "Trifluoperazine 5 MG Oral Tablet"
    },
    {
      "id": "365",
      "name": "Trimethoprim 100 MG Oral Tablet"
    },
    {
      "id": "366",
      "name": "Sulfamethoxazole 400 MG / Trimethoprim 80 MG Oral Tablet"
    },
    {
      "id": "367",
      "name": "Sulfamethoxazole 800 MG / Trimethoprim 160 MG Oral Tablet"
    },
    {
      "id": "368",
      "name": "Urea 400 MG/ML Topical Cream"
    },
    {
      "id": "369",
      "name": "Zidovudine 100 MG Oral Capsule"
    },
    {
      "id": "370",
      "name": "felbamate 400 MG Oral Tablet"
    },
    {
      "id": "371",
      "name": "felbamate 600 MG Oral Tablet"
    },
    {
      "id": "372",
      "name": "prednicarbate 1 MG/ML Topical Cream"
    },
    {
      "id": "373",
      "name": "Prochlorperazine 10 MG Oral Tablet"
    },
    {
      "id": "374",
      "name": "torsemide 10 MG Oral Tablet"
    },
    {
      "id": "375",
      "name": "torsemide 100 MG Oral Tablet"
    },
    {
      "id": "376",
      "name": "torsemide 20 MG Oral Tablet"
    },
    {
      "id": "377",
      "name": "torsemide 5 MG Oral Tablet"
    },
    {
      "id": "378",
      "name": "calcipotriene 0.00005 MG/MG Topical Ointment"
    },
    {
      "id": "379",
      "name": "Tacrolimus 1 MG Oral Capsule"
    },
    {
      "id": "380",
      "name": "Tacrolimus 5 MG Oral Capsule"
    },
    {
      "id": "381",
      "name": "famciclovir 500 MG Oral Tablet"
    },
    {
      "id": "382",
      "name": "lamotrigine 100 MG Oral Tablet"
    },
    {
      "id": "383",
      "name": "lamotrigine 150 MG Oral Tablet"
    },
    {
      "id": "384",
      "name": "lamotrigine 200 MG Oral Tablet"
    },
    {
      "id": "385",
      "name": "lamotrigine 25 MG Disintegrating Oral Tablet"
    },
    {
      "id": "386",
      "name": "Folic Acid 0.4 MG Oral Tablet"
    },
    {
      "id": "387",
      "name": "Folic Acid 0.8 MG Oral Tablet"
    },
    {
      "id": "388",
      "name": "amcinonide 1 MG/ML Topical Lotion"
    },
    {
      "id": "389",
      "name": "Metronidazole 375 MG Oral Capsule"
    },
    {
      "id": "390",
      "name": "mycophenolate mofetil 250 MG Oral Capsule"
    },
    {
      "id": "391",
      "name": "Omeprazole 10 MG Delayed Release Oral Capsule"
    },
    {
      "id": "392",
      "name": "bicalutamide 50 MG Oral Tablet"
    },
    {
      "id": "393",
      "name": "Lamivudine 150 MG Oral Tablet"
    },
    {
      "id": "394",
      "name": "Lamivudine 10 MG/ML Oral Solution"
    },
    {
      "id": "395",
      "name": "Acarbose 50 MG Oral Tablet"
    },
    {
      "id": "396",
      "name": "Acarbose 100 MG Oral Tablet"
    },
    {
      "id": "397",
      "name": "Tretinoin 10 MG Oral Capsule"
    },
    {
      "id": "398",
      "name": "Phenobarbital 97.2 MG Oral Tablet"
    },
    {
      "id": "399",
      "name": "Phenobarbital 32.4 MG Oral Tablet"
    },
    {
      "id": "400",
      "name": "Phenobarbital 64.8 MG Oral Tablet"
    },
    {
      "id": "401",
      "name": "famciclovir 125 MG Oral Tablet"
    },
    {
      "id": "402",
      "name": "famciclovir 250 MG Oral Tablet"
    },
    {
      "id": "403",
      "name": "Riluzole 50 MG Oral Tablet"
    },
    {
      "id": "404",
      "name": "anastrozole 1 MG Oral Tablet"
    },
    {
      "id": "405",
      "name": "glimepiride 1 MG Oral Tablet"
    },
    {
      "id": "406",
      "name": "glimepiride 2 MG Oral Tablet"
    },
    {
      "id": "407",
      "name": "glimepiride 4 MG Oral Tablet"
    },
    {
      "id": "408",
      "name": "trandolapril 2 MG Oral Tablet"
    },
    {
      "id": "409",
      "name": "trandolapril 4 MG Oral Tablet"
    },
    {
      "id": "410",
      "name": "trandolapril 1 MG Oral Tablet"
    },
    {
      "id": "411",
      "name": "Cholecalciferol 1000 UNT Oral Tablet"
    },
    {
      "id": "412",
      "name": "Ciprofloxacin 100 MG Oral Tablet"
    },
    {
      "id": "413",
      "name": "potassium citrate 5 MEQ Extended Release Oral Tablet"
    },
    {
      "id": "414",
      "name": "potassium citrate 10 MEQ Extended Release Oral Tablet"
    },
    {
      "id": "415",
      "name": "Risperidone 1 MG/ML Oral Solution"
    },
    {
      "id": "416",
      "name": "Etodolac 500 MG Oral Tablet"
    },
    {
      "id": "417",
      "name": "Nevirapine 200 MG Oral Tablet"
    },
    {
      "id": "418",
      "name": "Tinidazole 500 MG Oral Tablet"
    },
    {
      "id": "419",
      "name": "Tetrabenazine 25 MG Oral Tablet"
    },
    {
      "id": "420",
      "name": "zafirlukast 20 MG Oral Tablet"
    },
    {
      "id": "421",
      "name": "Zidovudine 300 MG Oral Tablet"
    },
    {
      "id": "422",
      "name": "Acitretin 10 MG Oral Capsule"
    },
    {
      "id": "423",
      "name": "Acitretin 25 MG Oral Capsule"
    },
    {
      "id": "424",
      "name": "cabergoline 0.5 MG Oral Tablet"
    },
    {
      "id": "425",
      "name": "Ergocalciferol 400 UNT Oral Tablet"
    },
    {
      "id": "426",
      "name": "Levofloxacin 250 MG Oral Tablet"
    },
    {
      "id": "427",
      "name": "Levofloxacin 500 MG Oral Tablet"
    },
    {
      "id": "428",
      "name": "topiramate 25 MG Oral Tablet"
    },
    {
      "id": "429",
      "name": "topiramate 100 MG Oral Tablet"
    },
    {
      "id": "430",
      "name": "topiramate 200 MG Oral Tablet"
    },
    {
      "id": "431",
      "name": "Hydrochlorothiazide 12.5 MG Oral Capsule"
    },
    {
      "id": "432",
      "name": "carvedilol 6.25 MG Oral Tablet"
    },
    {
      "id": "433",
      "name": "carvedilol 12.5 MG Oral Tablet"
    },
    {
      "id": "434",
      "name": "carvedilol 25 MG Oral Tablet"
    },
    {
      "id": "435",
      "name": "olanzapine 2.5 MG Oral Tablet"
    },
    {
      "id": "436",
      "name": "mycophenolate mofetil 500 MG Oral Tablet"
    },
    {
      "id": "437",
      "name": "letrozole 2.5 MG Oral Tablet"
    },
    {
      "id": "438",
      "name": "Lamivudine 150 MG / Zidovudine 300 MG Oral Tablet"
    },
    {
      "id": "439",
      "name": "irbesartan 75 MG Oral Tablet"
    },
    {
      "id": "440",
      "name": "irbesartan 150 MG Oral Tablet"
    },
    {
      "id": "441",
      "name": "irbesartan 300 MG Oral Tablet"
    },
    {
      "id": "442",
      "name": "12 HR Carbamazepine 300 MG Extended Release Oral Capsule"
    },
    {
      "id": "443",
      "name": "Acarbose 25 MG Oral Tablet"
    },
    {
      "id": "444",
      "name": "12 HR Carbamazepine 200 MG Extended Release Oral Capsule"
    },
    {
      "id": "445",
      "name": "zolmitriptan 2.5 MG Oral Tablet"
    },
    {
      "id": "446",
      "name": "zolmitriptan 5 MG Oral Tablet"
    },
    {
      "id": "447",
      "name": "tolcapone 100 MG Oral Tablet"
    },
    {
      "id": "448",
      "name": "montelukast 10 MG Oral Tablet"
    },
    {
      "id": "449",
      "name": "paregoric 0.4 MG/ML Oral Solution"
    },
    {
      "id": "450",
      "name": "repaglinide 1 MG Oral Tablet"
    },
    {
      "id": "451",
      "name": "repaglinide 0.5 MG Oral Tablet"
    },
    {
      "id": "452",
      "name": "repaglinide 2 MG Oral Tablet"
    },
    {
      "id": "453",
      "name": "Hydrochlorothiazide 12.5 MG / valsartan 80 MG Oral Tablet"
    },
    {
      "id": "454",
      "name": "Hydrochlorothiazide 12.5 MG / valsartan 160 MG Oral Tablet"
    },
    {
      "id": "455",
      "name": "Fenofibrate 67 MG Oral Capsule"
    },
    {
      "id": "456",
      "name": "capecitabine 150 MG Oral Tablet"
    },
    {
      "id": "457",
      "name": "capecitabine 500 MG Oral Tablet"
    },
    {
      "id": "458",
      "name": "Omeprazole 40 MG Delayed Release Oral Capsule"
    },
    {
      "id": "459",
      "name": "Simvastatin 80 MG Oral Tablet"
    },
    {
      "id": "460",
      "name": "cefdinir 300 MG Oral Capsule"
    },
    {
      "id": "461",
      "name": "Citalopram 20 MG Oral Tablet"
    },
    {
      "id": "462",
      "name": "Ribavirin 200 MG Oral Tablet [Moderiba]"
    },
    {
      "id": "463",
      "name": "Mafenide 85 MG/ML Topical Cream [Sulfamylon]"
    },
    {
      "id": "464",
      "name": "Metyrosine 250 MG Oral Capsule [Demser]"
    },
    {
      "id": "465",
      "name": "Secobarbital 100 MG Oral Capsule [Seconal Sodium]"
    },
    {
      "id": "466",
      "name": "Isocarboxazid 10 MG Oral Tablet [Marplan]"
    },
    {
      "id": "467",
      "name": "Capreomycin 500 MG/ML Injectable Solution [Capastat]"
    },
    {
      "id": "468",
      "name": "Pyrimethamine 25 MG Oral Tablet [Daraprim]"
    },
    {
      "id": "469",
      "name": "Estrogens, Conjugated (USP) 1.25 MG Oral Tablet [Premarin]"
    },
    {
      "id": "470",
      "name": "Ketoconazole 20 MG/ML Topical Cream"
    },
    {
      "id": "471",
      "name": "Hydrocortisone 0.01 MG/MG Topical Ointment"
    },
    {
      "id": "472",
      "name": "Malathion 5 MG/ML Topical Lotion"
    },
    {
      "id": "473",
      "name": "Desonide 0.0005 MG/MG Topical Ointment"
    },
    {
      "id": "474",
      "name": "Hydrocortisone 10 MG/ML / Neomycin 3.5 MG/ML / Polymyxin B 10000 UNT/ML Otic Solution"
    },
    {
      "id": "475",
      "name": "isoniazid 100 MG/ML Injectable Solution"
    },
    {
      "id": "476",
      "name": "Famotidine 10 MG/ML Injectable Solution"
    },
    {
      "id": "477",
      "name": "Azithromycin 600 MG Oral Tablet"
    },
    {
      "id": "478",
      "name": "Hydrocortisone 10 MG/ML / Neomycin 3.5 MG/ML / Polymyxin B 10000 UNT/ML Ophthalmic Suspension"
    },
    {
      "id": "479",
      "name": "diphtheria toxoid vaccine, inactivated 4 UNT/ML / tetanus toxoid vaccine, inactivated 4 UNT/ML Injectable Suspension"
    },
    {
      "id": "480",
      "name": "leflunomide 10 MG Oral Tablet"
    },
    {
      "id": "481",
      "name": "leflunomide 20 MG Oral Tablet"
    },
    {
      "id": "482",
      "name": "telmisartan 40 MG Oral Tablet"
    },
    {
      "id": "483",
      "name": "telmisartan 80 MG Oral Tablet"
    },
    {
      "id": "484",
      "name": "topiramate 25 MG Oral Capsule"
    },
    {
      "id": "485",
      "name": "topiramate 15 MG Oral Capsule"
    },
    {
      "id": "486",
      "name": "celecoxib 100 MG Oral Capsule"
    },
    {
      "id": "487",
      "name": "celecoxib 200 MG Oral Capsule"
    },
    {
      "id": "488",
      "name": "modafinil 200 MG Oral Tablet"
    },
    {
      "id": "489",
      "name": "Lisinopril 30 MG Oral Tablet"
    },
    {
      "id": "490",
      "name": "Lamivudine 100 MG Oral Tablet"
    },
    {
      "id": "491",
      "name": "Anthralin 10 MG/ML Topical Cream [Drithocreme]"
    },
    {
      "id": "492",
      "name": "Dornase Alfa 1 MG/ML Inhalant Solution [Pulmozyme]"
    },
    {
      "id": "493",
      "name": "Estramustine 140 MG Oral Capsule [Emcyt]"
    },
    {
      "id": "494",
      "name": "Flurandrenolide 0.5 MG/ML Topical Lotion [Cordran]"
    },
    {
      "id": "495",
      "name": "Flurandrenolide 0.0005 MG/MG Topical Ointment [Cordran]"
    },
    {
      "id": "496",
      "name": "Dexamethasone 1 MG/ML Ophthalmic Suspension [Maxidex]"
    },
    {
      "id": "497",
      "name": "Echothiophate Iodide 1.25 MG/ML Ophthalmic Solution [Phospholine Iodide]"
    },
    {
      "id": "498",
      "name": "Epoetin Alfa 10000 UNT/ML Injectable Solution [Epogen]"
    },
    {
      "id": "499",
      "name": "Epoetin Alfa 10000 UNT/ML Injectable Solution [Procrit]"
    },
    {
      "id": "500",
      "name": "1 ML Epoetin Alfa 2000 UNT/ML Injection [Epogen]"
    },
    {
      "id": "501",
      "name": "1 ML Epoetin Alfa 2000 UNT/ML Injection [Procrit]"
    },
    {
      "id": "502",
      "name": "1 ML Epoetin Alfa 3000 UNT/ML Injection [Epogen]"
    },
    {
      "id": "503",
      "name": "1 ML Epoetin Alfa 3000 UNT/ML Injection [Procrit]"
    },
    {
      "id": "504",
      "name": "1 ML Epoetin Alfa 4000 UNT/ML Injection [Epogen]"
    },
    {
      "id": "505",
      "name": "1 ML Epoetin Alfa 4000 UNT/ML Injection [Procrit]"
    },
    {
      "id": "506",
      "name": "clocortolone 1 MG/ML Topical Cream [Cloderm]"
    },
    {
      "id": "507",
      "name": "Erythromycin 250 MG Delayed Release Oral Tablet [Ery-Tab]"
    },
    {
      "id": "508",
      "name": "Erythromycin 333 MG Delayed Release Oral Tablet [Ery-Tab]"
    },
    {
      "id": "509",
      "name": "Erythromycin 500 MG Delayed Release Oral Tablet [Ery-Tab]"
    },
    {
      "id": "510",
      "name": "lansoprazole 15 MG Disintegrating Oral Tablet [Prevacid]"
    },
    {
      "id": "511",
      "name": "aluminum chloride 200 MG/ML Topical Solution [Drysol]"
    },
    {
      "id": "512",
      "name": "Erythromycin 0.02 MG/MG Topical Gel [Erygel]"
    },
    {
      "id": "513",
      "name": "Iron-Dextran Complex 50 MG/ML Injectable Solution [InFed]"
    },
    {
      "id": "514",
      "name": "Levocarnitine 100 MG/ML Oral Solution [Carnitor]"
    },
    {
      "id": "515",
      "name": "Hydrocortisone 10 MG/ML Topical Cream [Ala-Cort]"
    },
    {
      "id": "516",
      "name": "Estradiol 5 MG/ML Injectable Solution [Depo-estradiol]"
    },
    {
      "id": "517",
      "name": "Halcinonide 1 MG/ML Topical Cream [Halog]"
    },
    {
      "id": "518",
      "name": "Halcinonide 0.001 MG/MG Topical Ointment [Halog]"
    },
    {
      "id": "519",
      "name": "isoniazid 50 MG / Pyrazinamide 300 MG / Rifampin 120 MG Oral Tablet [Rifater]"
    },
    {
      "id": "520",
      "name": "Ethotoin 250 MG Oral Tablet [Peganone]"
    },
    {
      "id": "521",
      "name": "Lithium Carbonate 300 MG Extended Release Oral Tablet [Lithobid]"
    },
    {
      "id": "522",
      "name": "mesalamine 250 MG Extended Release Oral Capsule [Pentasa]"
    },
    {
      "id": "523",
      "name": "Lodoxamide 1 MG/ML Ophthalmic Solution [Alomide]"
    },
    {
      "id": "524",
      "name": "Isosorbide Dinitrate 20 MG Oral Tablet"
    },
    {
      "id": "525",
      "name": "Interferon Alfa-2b 6000000 UNT/ML Injectable Solution [Intron A]"
    },
    {
      "id": "526",
      "name": "Interferon Alfa-2b 50000000 UNT Injection [Intron A]"
    },
    {
      "id": "527",
      "name": "interferon beta-1b 0.3 MG Injection [Betaseron]"
    },
    {
      "id": "528",
      "name": "Methoxsalen 10 MG Oral Capsule [8-MOP]"
    },
    {
      "id": "529",
      "name": "methsuximide 300 MG Oral Capsule [Celontin]"
    },
    {
      "id": "530",
      "name": "Natamycin 50 MG/ML Ophthalmic Suspension [Natacyn]"
    },
    {
      "id": "531",
      "name": "oxiconazole 10 MG/ML Topical Cream [Oxistat]"
    },
    {
      "id": "532",
      "name": "oxiconazole 10 MG/ML Topical Lotion [Oxistat]"
    },
    {
      "id": "533",
      "name": "meningococcal group A polysaccharide 0.1 MG/ML / meningococcal group C polysaccharide 0.1 MG/ML / MENINGOCOCCAL POLYSACCHARIDE VACCINE GROUP W-135 0.1 MG/ML / MENINGOCOCCAL POLYSACCHARIDE VACCINE GROUP Y 0.1 MG/ML Injectable Solution [Menomune A/C/Y/"
    },
    {
      "id": "534",
      "name": "Nitroglycerin 0.02 MG/MG Topical Ointment [Nitro-Bid]"
    },
    {
      "id": "535",
      "name": "Nitroglycerin 0.3 MG Sublingual Tablet [Nitrostat]"
    },
    {
      "id": "536",
      "name": "Nitroglycerin 0.4 MG Sublingual Tablet [Nitrostat]"
    },
    {
      "id": "537",
      "name": "Nitroglycerin 0.6 MG Sublingual Tablet [Nitrostat]"
    },
    {
      "id": "538",
      "name": "Minocycline 50 MG Oral Tablet"
    },
    {
      "id": "539",
      "name": "Minocycline 100 MG Oral Tablet"
    },
    {
      "id": "540",
      "name": "Penicillamine 250 MG Oral Capsule [Cuprimine]"
    },
    {
      "id": "541",
      "name": "Penicillamine 250 MG Oral Tablet [Depen]"
    },
    {
      "id": "542",
      "name": "Mitotane 500 MG Oral Tablet [Lysodren]"
    },
    {
      "id": "543",
      "name": "rimexolone 10 MG/ML Ophthalmic Suspension [Vexol]"
    },
    {
      "id": "544",
      "name": "Procarbazine 50 MG Oral Capsule [Matulane]"
    },
    {
      "id": "545",
      "name": "Diazoxide 50 MG/ML Oral Suspension [Proglycem]"
    },
    {
      "id": "546",
      "name": "Somatropin 5 MG Injection [Humatrope]"
    },
    {
      "id": "547",
      "name": "Somatropin 5 MG/ML Injectable Solution [Saizen]"
    },
    {
      "id": "548",
      "name": "Tobramycin 0.003 MG/MG Ophthalmic Ointment [Tobrex]"
    },
    {
      "id": "549",
      "name": "crotamiton 100 MG/ML Topical Cream [Eurax]"
    },
    {
      "id": "550",
      "name": "crotamiton 100 MG/ML Topical Lotion [Eurax]"
    },
    {
      "id": "551",
      "name": "Succimer 100 MG Oral Capsule [Chemet]"
    },
    {
      "id": "552",
      "name": "sargramostim 0.25 MG/ML Injectable Solution [Leukine]"
    },
    {
      "id": "553",
      "name": "Sucralfate 100 MG/ML Oral Suspension [Carafate]"
    },
    {
      "id": "554",
      "name": "sulconazole 10 MG/ML Topical Cream [Exelderm]"
    },
    {
      "id": "555",
      "name": "sulconazole 10 MG/ML Topical Solution [Exelderm]"
    },
    {
      "id": "556",
      "name": "Silver Sulfadiazine 10 MG/ML Topical Cream [SSD]"
    },
    {
      "id": "557",
      "name": "Silver Sulfadiazine 10 MG/ML Topical Cream [Thermazene]"
    },
    {
      "id": "558",
      "name": "Theophylline 100 MG Extended Release Oral Tablet [Theochron]"
    },
    {
      "id": "559",
      "name": "Theophylline 300 MG Extended Release Oral Tablet [Theochron]"
    },
    {
      "id": "560",
      "name": "Tretinoin 0.25 MG/ML Topical Cream [Avita]"
    },
    {
      "id": "561",
      "name": "Tretinoin 0.00025 MG/MG Topical Gel [Avita]"
    },
    {
      "id": "562",
      "name": "Sulfamethoxazole 40 MG/ML / Trimethoprim 8 MG/ML Oral Suspension [Sulfatrim]"
    },
    {
      "id": "563",
      "name": "Fluorometholone 2.5 MG/ML Ophthalmic Suspension [FML Forte Liquifilm]"
    },
    {
      "id": "564",
      "name": "Estrogens, Conjugated (USP) 0.3 MG Oral Tablet [Premarin]"
    },
    {
      "id": "565",
      "name": "thyroid (USP) 15 MG Oral Tablet [Armour Thyroid]"
    },
    {
      "id": "566",
      "name": "thyroid (USP) 30 MG Oral Tablet [Armour Thyroid]"
    },
    {
      "id": "567",
      "name": "thyroid (USP) 60 MG Oral Tablet [Armour Thyroid]"
    },
    {
      "id": "568",
      "name": "thyroid (USP) 90 MG Oral Tablet [Armour Thyroid]"
    },
    {
      "id": "569",
      "name": "thyroid (USP) 120 MG Oral Tablet [Armour Thyroid]"
    },
    {
      "id": "570",
      "name": "thyroid (USP) 180 MG Oral Tablet [Armour Thyroid]"
    },
    {
      "id": "571",
      "name": "thyroid (USP) 240 MG Oral Tablet [Armour Thyroid]"
    },
    {
      "id": "572",
      "name": "thyroid (USP) 300 MG Oral Tablet [Armour Thyroid]"
    },
    {
      "id": "573",
      "name": "Timolol 2.5 MG/ML Ophthalmic Solution [Betimol]"
    },
    {
      "id": "574",
      "name": "Timolol 5 MG/ML Ophthalmic Solution [Betimol]"
    },
    {
      "id": "575",
      "name": "Triamterene 100 MG Oral Capsule [Dyrenium]"
    },
    {
      "id": "576",
      "name": "Triamterene 50 MG Oral Capsule [Dyrenium]"
    },
    {
      "id": "577",
      "name": "Trientine 250 MG Oral Capsule [Syprine]"
    },
    {
      "id": "578",
      "name": "Dexamethasone 0.001 MG/MG / Tobramycin 0.003 MG/MG Ophthalmic Ointment [Tobradex]"
    },
    {
      "id": "579",
      "name": "Hydrocortisone 100 MG Injection [A-Hydrocort]"
    },
    {
      "id": "580",
      "name": "Estrogens, Conjugated (USP) 0.9 MG Oral Tablet [Premarin]"
    },
    {
      "id": "581",
      "name": "Praziquantel 600 MG Oral Tablet [Biltricide]"
    },
    {
      "id": "582",
      "name": "prednisolone 1.2 MG/ML Ophthalmic Suspension [Pred Mild]"
    },
    {
      "id": "583",
      "name": "potassium phosphate 305 MG / Sodium Phosphate, Monobasic 700 MG Oral Tablet [K-Phos No 2]"
    },
    {
      "id": "584",
      "name": "Citric Acid 66.8 MG/ML / potassium citrate 220 MG/ML Oral Solution [Cytra-K]"
    },
    {
      "id": "585",
      "name": "Cholecalciferol 400 UNT Oral Tablet [Delta D3]"
    },
    {
      "id": "586",
      "name": "Bacitracin 5000 UNT/ML Injectable Solution [Baci-IM]"
    },
    {
      "id": "587",
      "name": "Cysteamine 150 MG Oral Capsule [Cystagon]"
    },
    {
      "id": "588",
      "name": "Cysteamine 50 MG Oral Capsule [Cystagon]"
    },
    {
      "id": "589",
      "name": "Acetaminophen 325 MG / butalbital 50 MG Oral Tablet [Marten-Tab]"
    },
    {
      "id": "590",
      "name": "apraclonidine 10 MG/ML Ophthalmic Solution [Iopidine]"
    },
    {
      "id": "591",
      "name": "Pentosan Polysulfate 100 MG Oral Capsule [Elmiron]"
    },
    {
      "id": "592",
      "name": "Albendazole 200 MG Oral Tablet [Albenza]"
    },
    {
      "id": "593",
      "name": "Acetaminophen 325 MG / butalbital 50 MG / Caffeine 40 MG Oral Capsule [Margesic]"
    },
    {
      "id": "594",
      "name": "penciclovir 10 MG/ML Topical Cream [Denavir]"
    },
    {
      "id": "595",
      "name": "6-Aminocaproic Acid 500 MG Oral Tablet [Amicar]"
    },
    {
      "id": "596",
      "name": "zileuton 600 MG Oral Tablet [Zyflo]"
    },
    {
      "id": "597",
      "name": "Carbamazepine 200 MG Oral Tablet [Epitol]"
    },
    {
      "id": "598",
      "name": "Aspirin 325 MG Oral Tablet [Bayer Aspirin]"
    },
    {
      "id": "599",
      "name": "Estrogens, Conjugated (USP) 25 MG Injection [Premarin]"
    },
    {
      "id": "600",
      "name": "Aspirin 325 MG Oral Tablet"
    },
    {
      "id": "601",
      "name": "Estrogens, Esterified (USP) 0.3 MG Oral Tablet [Menest]"
    },
    {
      "id": "602",
      "name": "Nelfinavir 250 MG Oral Tablet [Viracept]"
    },
    {
      "id": "603",
      "name": "Epoetin Alfa 20000 UNT/ML Injectable Solution [Epogen]"
    },
    {
      "id": "604",
      "name": "Epoetin Alfa 20000 UNT/ML Injectable Solution [Procrit]"
    },
    {
      "id": "605",
      "name": "Estrogens, Esterified (USP) 0.625 MG Oral Tablet [Menest]"
    },
    {
      "id": "606",
      "name": "Estrogens, Esterified (USP) 1.25 MG Oral Tablet [Menest]"
    },
    {
      "id": "607",
      "name": "aminosalicylic acid 4000 MG Oral Granules [Paser D/R]"
    },
    {
      "id": "608",
      "name": "tazarotene 0.0005 MG/MG Topical Gel [Tazorac]"
    },
    {
      "id": "609",
      "name": "tazarotene 0.001 MG/MG Topical Gel [Tazorac]"
    },
    {
      "id": "610",
      "name": "Estrogens, Esterified (USP) 2.5 MG Oral Tablet [Menest]"
    },
    {
      "id": "611",
      "name": "Cimetidine 60 MG/ML Oral Solution"
    },
    {
      "id": "612",
      "name": "Dolasetron 50 MG Oral Tablet [Anzemet]"
    },
    {
      "id": "613",
      "name": "Dolasetron 100 MG Oral Tablet [Anzemet]"
    },
    {
      "id": "614",
      "name": "Sodium phenylbutyrate 500 MG Oral Tablet [Buphenyl]"
    },
    {
      "id": "615",
      "name": "Becaplermin 0.0001 MG/MG Topical Gel [Regranex]"
    },
    {
      "id": "616",
      "name": "emedastine 0.5 MG/ML Ophthalmic Solution [Emadine]"
    },
    {
      "id": "617",
      "name": "loteprednol etabonate 2 MG/ML Ophthalmic Suspension [Alrex]"
    },
    {
      "id": "618",
      "name": "loteprednol etabonate 5 MG/ML Ophthalmic Suspension [Lotemax]"
    },
    {
      "id": "619",
      "name": "brinzolamide 10 MG/ML Ophthalmic Suspension [Azopt]"
    },
    {
      "id": "620",
      "name": "Ciprofloxacin 2 MG/ML / Hydrocortisone 10 MG/ML Otic Suspension [Cipro HC]"
    },
    {
      "id": "621",
      "name": "Sacrosidase 8500 UNT/ML Oral Solution [Sucraid]"
    },
    {
      "id": "622",
      "name": "Thalidomide 50 MG Oral Capsule [Thalomid]"
    },
    {
      "id": "623",
      "name": "efavirenz 200 MG Oral Capsule [Sustiva]"
    },
    {
      "id": "624",
      "name": "efavirenz 50 MG Oral Capsule [Sustiva]"
    },
    {
      "id": "625",
      "name": "insulin human, isophane 70 UNT/ML / Regular Insulin, Human 30 UNT/ML Injectable Suspension [Novolin]"
    },
    {
      "id": "626",
      "name": "1 ML Epoetin Alfa 40000 UNT/ML Injection [Procrit]"
    },
    {
      "id": "627",
      "name": "Lamivudine 5 MG/ML Oral Solution [Epivir HBV]"
    },
    {
      "id": "628",
      "name": "miglitol 25 MG Oral Tablet [Glyset]"
    },
    {
      "id": "629",
      "name": "miglitol 50 MG Oral Tablet [Glyset]"
    },
    {
      "id": "630",
      "name": "miglitol 100 MG Oral Tablet [Glyset]"
    },
    {
      "id": "631",
      "name": "alitretinoin 0.001 MG/MG Topical Gel [Panretin]"
    },
    {
      "id": "632",
      "name": "Auranofin 3 MG Oral Capsule [Ridaura]"
    },
    {
      "id": "633",
      "name": "Acetic Acid 20 MG/ML / Hydrocortisone 10 MG/ML Otic Solution [Acetasol HC]"
    },
    {
      "id": "634",
      "name": "salmon calcitonin 200 UNT/ML Injectable Solution [Miacalcin]"
    },
    {
      "id": "635",
      "name": "Bacitracin 0.5 UNT/MG / Polymyxin B 10 UNT/MG Ophthalmic Ointment [Polycin-B]"
    },
    {
      "id": "636",
      "name": "Betaxolol 2.5 MG/ML Ophthalmic Suspension [Betoptic S]"
    },
    {
      "id": "637",
      "name": "Dexamethasone 1 MG/ML Ophthalmic Solution"
    },
    {
      "id": "638",
      "name": "Theophylline 100 MG Extended Release Oral Tablet"
    },
    {
      "id": "639",
      "name": "Folic Acid 5 MG/ML Injectable Solution"
    },
    {
      "id": "640",
      "name": "168 HR Estradiol 0.00208 MG/HR Transdermal Patch"
    },
    {
      "id": "641",
      "name": "168 HR Estradiol 0.00417 MG/HR Transdermal Patch"
    },
    {
      "id": "642",
      "name": "Estrogens, Esterified (USP) 0.625 MG / Methyltestosterone 1.25 MG Oral Tablet"
    },
    {
      "id": "643",
      "name": "1 ML Buprenorphine 0.3 MG/ML Injection"
    },
    {
      "id": "644",
      "name": "Aspirin 325 MG / butalbital 50 MG / Caffeine 40 MG Oral Capsule"
    },
    {
      "id": "645",
      "name": "mesalamine 66.7 MG/ML Enema"
    },
    {
      "id": "646",
      "name": "Acetaminophen 325 MG / butalbital 50 MG / Caffeine 40 MG Oral Capsule"
    },
    {
      "id": "647",
      "name": "Acetaminophen 325 MG / butalbital 50 MG / Caffeine 40 MG Oral Tablet"
    },
    {
      "id": "648",
      "name": "Betamethasone 0.5 MG/ML Topical Cream"
    },
    {
      "id": "649",
      "name": "Amoxicillin 50 MG/ML Oral Suspension"
    },
    {
      "id": "650",
      "name": "Griseofulvin 25 MG/ML Oral Suspension"
    },
    {
      "id": "651",
      "name": "Griseofulvin 250 MG Oral Tablet"
    },
    {
      "id": "652",
      "name": "Betamethasone 0.5 MG/ML Topical Lotion"
    },
    {
      "id": "653",
      "name": "Clarithromycin 25 MG/ML Oral Suspension"
    },
    {
      "id": "654",
      "name": "168 HR Estradiol 0.00312 MG/HR Transdermal Patch"
    },
    {
      "id": "655",
      "name": "cyclosporine, modified 100 MG Oral Capsule"
    },
    {
      "id": "656",
      "name": "84 HR Estradiol 0.00156 MG/HR Transdermal Patch"
    },
    {
      "id": "657",
      "name": "168 HR Estradiol 0.00104 MG/HR Transdermal Patch"
    },
    {
      "id": "658",
      "name": "montelukast 5 MG Chewable Tablet"
    },
    {
      "id": "659",
      "name": "Ofloxacin 3 MG/ML Otic Solution"
    },
    {
      "id": "660",
      "name": "cilostazol 100 MG Oral Tablet"
    },
    {
      "id": "661",
      "name": "cilostazol 50 MG Oral Tablet"
    },
    {
      "id": "662",
      "name": "abacavir 300 MG Oral Tablet"
    },
    {
      "id": "663",
      "name": "Metronidazole 7.5 MG/ML Topical Lotion"
    },
    {
      "id": "664",
      "name": "Levalbuterol 0.417 MG/ML Inhalant Solution"
    },
    {
      "id": "665",
      "name": "Griseofulvin 125 MG Oral Tablet"
    },
    {
      "id": "666",
      "name": "84 HR Estradiol 0.00208 MG/HR Transdermal Patch"
    },
    {
      "id": "667",
      "name": "84 HR Estradiol 0.00417 MG/HR Transdermal Patch"
    },
    {
      "id": "668",
      "name": "Benzoyl Peroxide 0.05 MG/MG / Erythromycin 0.03 MG/MG Topical Gel"
    },
    {
      "id": "669",
      "name": "Polymyxin B 10000 UNT/ML / Trimethoprim 1 MG/ML Ophthalmic Solution"
    },
    {
      "id": "670",
      "name": "72 HR Fentanyl 0.025 MG/HR Transdermal Patch"
    },
    {
      "id": "671",
      "name": "72 HR Fentanyl 0.05 MG/HR Transdermal Patch"
    },
    {
      "id": "672",
      "name": "72 HR Fentanyl 0.1 MG/HR Transdermal Patch"
    },
    {
      "id": "673",
      "name": "Albuterol 1 MG/ML Inhalant Solution"
    },
    {
      "id": "674",
      "name": "Cholecalciferol 1000 UNT Oral Capsule"
    },
    {
      "id": "675",
      "name": "Tretinoin 0.0005 MG/MG Topical Gel"
    },
    {
      "id": "676",
      "name": "Ribavirin 200 MG Oral Tablet"
    },
    {
      "id": "677",
      "name": "84 HR Estradiol 0.00104 MG/HR Transdermal Patch"
    },
    {
      "id": "678",
      "name": "Fluoxetine 20 MG Oral Tablet"
    },
    {
      "id": "679",
      "name": "prednisolone 5 MG/ML Oral Solution"
    },
    {
      "id": "680",
      "name": "ciclopirox 10 MG/ML Medicated Shampoo"
    },
    {
      "id": "681",
      "name": "Cholecalciferol 400 UNT/ML Oral Solution"
    },
    {
      "id": "682",
      "name": "ferrous sulfate 75 MG/ML Oral Solution"
    },
    {
      "id": "683",
      "name": "Ethosuximide 50 MG/ML Oral Solution"
    },
    {
      "id": "684",
      "name": "pantoprazole 20 MG Delayed Release Oral Tablet"
    },
    {
      "id": "685",
      "name": "lamotrigine 50 MG Disintegrating Oral Tablet"
    },
    {
      "id": "686",
      "name": "lamotrigine 200 MG Disintegrating Oral Tablet"
    },
    {
      "id": "687",
      "name": "Budesonide 0.5 MG/ML Inhalant Solution"
    },
    {
      "id": "688",
      "name": "Aspirin 25 MG / Dipyridamole 200 MG Oral Capsule"
    },
    {
      "id": "689",
      "name": "atorvastatin 80 MG Oral Tablet"
    },
    {
      "id": "690",
      "name": "alosetron 1 MG Oral Tablet"
    },
    {
      "id": "691",
      "name": "Methylprednisolone 4 MG Oral Tablet"
    },
    {
      "id": "692",
      "name": "modafinil 100 MG Oral Tablet"
    },
    {
      "id": "693",
      "name": "Progesterone 100 MG Oral Capsule"
    },
    {
      "id": "694",
      "name": "Carbidopa 25 MG Oral Tablet"
    },
    {
      "id": "695",
      "name": "Digoxin 0.125 MG Oral Tablet [Digitek]"
    },
    {
      "id": "696",
      "name": "Digoxin 0.25 MG Oral Tablet [Digitek]"
    },
    {
      "id": "697",
      "name": "Terazosin 10 MG Oral Capsule"
    },
    {
      "id": "698",
      "name": "rifapentine 150 MG Oral Tablet [Priftin]"
    },
    {
      "id": "699",
      "name": "Etanercept 25 MG/ML Injectable Solution [Enbrel]"
    },
    {
      "id": "700",
      "name": "Nystatin 100 UNT/MG Topical Powder [Nystop]"
    },
    {
      "id": "701",
      "name": "rosiglitazone 2 MG Oral Tablet [Avandia]"
    },
    {
      "id": "702",
      "name": "rosiglitazone 4 MG Oral Tablet [Avandia]"
    },
    {
      "id": "703",
      "name": "Oseltamivir 75 MG Oral Capsule [Tamiflu]"
    },
    {
      "id": "704",
      "name": "bexarotene 75 MG Oral Capsule [Targretin]"
    },
    {
      "id": "705",
      "name": "Trimethoprim 10 MG/ML Oral Solution [Primsol]"
    },
    {
      "id": "706",
      "name": "Ramipril 10 MG Oral Capsule"
    },
    {
      "id": "707",
      "name": "lamotrigine 25 MG Oral Tablet"
    },
    {
      "id": "708",
      "name": "telmisartan 20 MG Oral Tablet"
    },
    {
      "id": "709",
      "name": "prednisolone 3 MG/ML Oral Solution"
    },
    {
      "id": "710",
      "name": "Hydrochlorothiazide 12.5 MG / telmisartan 40 MG Oral Tablet"
    },
    {
      "id": "711",
      "name": "Hydrochlorothiazide 12.5 MG / telmisartan 80 MG Oral Tablet"
    },
    {
      "id": "712",
      "name": "Mirtazapine 15 MG Disintegrating Oral Tablet"
    },
    {
      "id": "713",
      "name": "Mirtazapine 30 MG Disintegrating Oral Tablet"
    },
    {
      "id": "714",
      "name": "benzonatate 200 MG Oral Capsule"
    },
    {
      "id": "715",
      "name": "Mirtazapine 45 MG Disintegrating Oral Tablet"
    },
    {
      "id": "716",
      "name": "gabapentin 50 MG/ML Oral Solution"
    },
    {
      "id": "717",
      "name": "doxycycline hyclate 20 MG Oral Tablet"
    },
    {
      "id": "718",
      "name": "oxcarbazepine 60 MG/ML Oral Suspension"
    },
    {
      "id": "719",
      "name": "olanzapine 20 MG Oral Tablet"
    },
    {
      "id": "720",
      "name": "Citalopram 10 MG Oral Tablet"
    },
    {
      "id": "721",
      "name": "ropinirole 3 MG Oral Tablet"
    },
    {
      "id": "722",
      "name": "travoprost 0.04 MG/ML Ophthalmic Solution"
    },
    {
      "id": "723",
      "name": "Didanosine 125 MG Delayed Release Oral Capsule"
    },
    {
      "id": "724",
      "name": "Didanosine 250 MG Delayed Release Oral Capsule"
    },
    {
      "id": "725",
      "name": "Didanosine 200 MG Delayed Release Oral Capsule"
    },
    {
      "id": "726",
      "name": "Fluorouracil 5 MG/ML Topical Cream"
    },
    {
      "id": "727",
      "name": "zolmitriptan 2.5 MG Disintegrating Oral Tablet"
    },
    {
      "id": "728",
      "name": "almotriptan 6.25 MG Oral Tablet"
    },
    {
      "id": "729",
      "name": "almotriptan 12.5 MG Oral Tablet"
    },
    {
      "id": "730",
      "name": "Clindamycin 300 MG Oral Capsule"
    },
    {
      "id": "731",
      "name": "Famotidine 40 MG Oral Tablet"
    },
    {
      "id": "732",
      "name": "Prochlorperazine 25 MG Rectal Suppository [Compro]"
    },
    {
      "id": "733",
      "name": "dofetilide 0.125 MG Oral Capsule [Tikosyn]"
    },
    {
      "id": "734",
      "name": "dofetilide 0.25 MG Oral Capsule [Tikosyn]"
    },
    {
      "id": "735",
      "name": "tazarotene 0.5 MG/ML Topical Cream [Tazorac]"
    },
    {
      "id": "736",
      "name": "tazarotene 1 MG/ML Topical Cream [Tazorac]"
    },
    {
      "id": "737",
      "name": "Fluorouracil 5 MG/ML Topical Cream [Carac]"
    },
    {
      "id": "738",
      "name": "nilutamide 150 MG Oral Tablet [Nilandron]"
    },
    {
      "id": "739",
      "name": "Methotrexate 5 MG Oral Tablet [Trexall]"
    },
    {
      "id": "740",
      "name": "Methotrexate 10 MG Oral Tablet [Trexall]"
    },
    {
      "id": "741",
      "name": "Methotrexate 7.5 MG Oral Tablet [Trexall]"
    },
    {
      "id": "742",
      "name": "Methotrexate 15 MG Oral Tablet [Trexall]"
    },
    {
      "id": "743",
      "name": "Didanosine 400 MG Delayed Release Oral Capsule"
    },
    {
      "id": "744",
      "name": "dofetilide 0.5 MG Oral Capsule [Tikosyn]"
    },
    {
      "id": "745",
      "name": "Insulin Glargine 100 UNT/ML Injectable Solution [Lantus]"
    },
    {
      "id": "746",
      "name": "travoprost 0.04 MG/ML Ophthalmic Solution [Travatan]"
    },
    {
      "id": "747",
      "name": "abacavir 300 MG / Lamivudine 150 MG / Zidovudine 300 MG Oral Tablet"
    },
    {
      "id": "748",
      "name": "Acetic Acid 2.5 MG/ML Irrigation Solution"
    },
    {
      "id": "749",
      "name": "Acetylcysteine 100 MG/ML Inhalant Solution"
    },
    {
      "id": "750",
      "name": "Acetylcysteine 200 MG/ML Inhalant Solution"
    },
    {
      "id": "751",
      "name": "Acyclovir 40 MG/ML Oral Suspension"
    },
    {
      "id": "752",
      "name": "adapalene 0.001 MG/MG Topical Gel"
    },
    {
      "id": "753",
      "name": "Alprazolam 0.25 MG Oral Tablet"
    },
    {
      "id": "754",
      "name": "Alprazolam 0.5 MG Oral Tablet"
    },
    {
      "id": "755",
      "name": "aluminum chloride 200 MG/ML Topical Solution"
    },
    {
      "id": "756",
      "name": "Amlodipine 10 MG Oral Tablet"
    },
    {
      "id": "757",
      "name": "Amlodipine 2.5 MG Oral Tablet"
    },
    {
      "id": "758",
      "name": "Amoxicillin 125 MG Chewable Tablet"
    },
    {
      "id": "759",
      "name": "Amoxicillin 250 MG Oral Capsule"
    },
    {
      "id": "760",
      "name": "Amoxicillin 400 MG Chewable Tablet"
    },
    {
      "id": "761",
      "name": "Amoxicillin 80 MG/ML Oral Suspension"
    },
    {
      "id": "762",
      "name": "Amoxicillin 500 MG Oral Capsule"
    },
    {
      "id": "763",
      "name": "Amoxicillin 500 MG Oral Tablet"
    },
    {
      "id": "764",
      "name": "Amoxicillin 875 MG Oral Tablet"
    },
    {
      "id": "765",
      "name": "Ampicillin 50 MG/ML Oral Suspension"
    },
    {
      "id": "766",
      "name": "Ampicillin 500 MG Oral Capsule"
    },
    {
      "id": "767",
      "name": "apraclonidine 5 MG/ML Ophthalmic Solution"
    },
    {
      "id": "768",
      "name": "Aspirin 81 MG Delayed Release Oral Tablet"
    },
    {
      "id": "769",
      "name": "Atovaquone 150 MG/ML Oral Suspension"
    },
    {
      "id": "770",
      "name": "Azithromycin 20 MG/ML Oral Suspension"
    },
    {
      "id": "771",
      "name": "Bacitracin 0.4 UNT/MG / Neomycin 0.0035 MG/MG / Polymyxin B 10 UNT/MG Ophthalmic Ointment"
    },
    {
      "id": "772",
      "name": "Bacitracin 0.5 UNT/MG Ophthalmic Ointment"
    },
    {
      "id": "773",
      "name": "Bacitracin 0.5 UNT/MG / Polymyxin B 10 UNT/MG Ophthalmic Ointment"
    },
    {
      "id": "774",
      "name": "Betamethasone 0.5 MG/ML / Clotrimazole 10 MG/ML Topical Cream"
    },
    {
      "id": "775",
      "name": "Betamethasone 0.5 MG/ML / Clotrimazole 10 MG/ML Topical Lotion"
    },
    {
      "id": "776",
      "name": "Betaxolol 5 MG/ML Ophthalmic Solution"
    },
    {
      "id": "777",
      "name": "bexarotene 75 MG Oral Capsule"
    },
    {
      "id": "778",
      "name": "bimatoprost 0.3 MG/ML Ophthalmic Solution"
    },
    {
      "id": "779",
      "name": "calcipotriene 0.05 MG/ML Topical Solution"
    },
    {
      "id": "780",
      "name": "Calcitriol 0.00025 MG Oral Capsule"
    },
    {
      "id": "781",
      "name": "Calcitriol 0.0005 MG Oral Capsule"
    },
    {
      "id": "782",
      "name": "Captopril 100 MG Oral Tablet"
    },
    {
      "id": "783",
      "name": "Captopril 12.5 MG Oral Tablet"
    },
    {
      "id": "784",
      "name": "Captopril 50 MG Oral Tablet"
    },
    {
      "id": "785",
      "name": "Carbamazepine 100 MG Chewable Tablet"
    },
    {
      "id": "786",
      "name": "Carbamazepine 20 MG/ML Oral Suspension"
    },
    {
      "id": "787",
      "name": "Carbamazepine 200 MG Oral Tablet"
    },
    {
      "id": "788",
      "name": "Carbidopa 25 MG / Levodopa 100 MG Extended Release Oral Tablet"
    }
  ]