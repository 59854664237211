import { createSlice } from "@reduxjs/toolkit";

export const userListSlice = createSlice({
    name: 'userList',

    initialState: {

        payload: [
           
        ]
    },
    reducers: {
        userList: (state, action) => {
           state.payload = action.payload
        },
    }
});

export const {userList} = userListSlice.actions
export default userListSlice.reducer