
export function setLoader(){
    window.start_load = function(){
        let element = document.createElement('div');
        element.id = 'loader';
        document.querySelector('body').appendChild(element);
        
      }
      window.stop_load =  function(){
        document.getElementById('loader').remove();
      }
    //   window.start_load();
}
