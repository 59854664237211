import React from 'react'
import Contact from './components/contact'
import Header from './components/Header'
import Footer from './components/footer'

const Careers = () => {
  return (
    <div>
        <Header />
        <div style={{  }}>
        <h1 className='ms-5 pt-3 text-center' > <b> <i>  Careers </i></b> </h1>

        <div 
        style={{ 
            height: '73vh'
         }}
        >

        </div>
        {/* <Contact /> */}
        </div>
        <Footer />
    </div>


  )
}

export default Careers