import { Facebook, FacebookRounded, Instagram, Twitter, YouTube } from '@mui/icons-material'
import React, {useState, useEffect} from 'react'
import { FaMailBulk, FaMap, FaMapMarkerAlt, FaMapPin, FaPhone } from 'react-icons/fa'
import { contactUs } from '../../api/api';
import Swal from 'sweetalert2';

const Contact = () => {
    const [data, setData] = useState();

    const handleChange = (e) => {
        setData({...data, [e.target.name]: e.target.value});
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const res = await contactUs(data);

        if(res.success){
            Swal.fire({
				title: "Message Sent",
				text: res.message,
				showConfirmButton: true,
				showCancelButton: false,
				confirmButtonColor: '#0a1f4ff3',
				confirmButtonText: "Close."
			})
            e.target.reset();
        }else{
           
            Swal.fire({
                icon: 'error',
                text: res.message,
                timer: 5000,
                toast: true,
                showConfirmButton: false,
                timerProgressBar: true,
                position: 'top-right'
                
            });
        }
    }

    useEffect(() => {
        console.log(data);
    }, [data])
  return (
    <div className='home-contact' 
    style={{ 
        // backgroundColor: 'yellow'
     }}>
        
       <div className='container'>
       
               
                <div className='row'>
                    <div className='col-lg-8 col-md-8 col-sm-12 col-xs-12'>
                    <p className='small-header mt-5'>GET IN TOUCH </p>
                        <p className='news-title'>
                        Send us a mail by filling the following text fields and we will
                        get back to you as soon as possible.
                        </p>
                        <form className=''
                        method='post'
                        onSubmit={handleSubmit}
                        >
                        <div className='px-2'>
                                <input type='text' className='form-control my-2 '
                                placeholder='Enter Your Full Name'
                                name='name'
                                onChange={handleChange}
                                required
                                />
                            </div>
                            <div className='d-flex justify-content-evenly px-2 '>
                              
                                <input type='text' className='form-control my-2 ms-1 w-50' 
                                placeholder='Enter Your Email Address'
                                name='email'
                                onChange={handleChange}
                                required
                                />
                                 <input type='text' className='form-control my-2 ms-1 w-50' 
                                placeholder='Enter Phone Number'
                                name='phone'
                                onChange={handleChange}
                                required
                                />
                            </div>
                            <div className='px-2'>
                                <input type='text' placeholder='Subject' className='form-control my-2 '
                                name='subject'
                                onChange={handleChange}
                                required
                                />
                                <textarea className='form-control my-3' rows={6} placeholder='Message'
                                name='message'
                                onChange={handleChange}
                                required
                                ></textarea>
                            </div>
                            <div
                            className=' py-3 px-2'
                            >
                            <button className='btn btn-lg form-control'
                            type='submit'
                            style={{ 
                                backgroundColor: '#0a1f4ff3',
                                color: 'white'
                                // width: '150px'
                             }}
                            >
                                Send 
                            </button>
                            </div>
                        </form>
                    </div>
                    <div className='col-lg-4 col-md-4 col-12 '>
                        <p className='small-header mt-5 text-center'> Contact Info </p>
                        <div className='ps-5'>
                            <p className='text-muted ms-3'> <FaPhone/> <b className='ms-2'>Phone</b> </p>
                            <p className='text-muted ms-3'>
                                +234 8035955425,
                                +234 9073777788
                            </p>
                        </div>
                        <div className='ps-5'>
                            <p className='text-muted ms-3'> <FaMailBulk /> <b className='ms-2'>Email</b> </p>
                            <p className='text-muted ms-3'>
                                team@abaafashab.com,
                                support@abaafashab.com,
                                abaafashabltd@gmail.com,
                            </p>
                        </div>
                        <div className='ps-5 mb-2'>
                            <p className='text-muted ms-3'> <FaMapMarkerAlt /> <b className='ms-2'>Address </b> </p>
                            <p>
                            <i className='text-muted ms-3'>
                                Opposite Tunga Central Mosque, Tunga, Minna, Niger State.
                            </i>
                            </p>
                        </div>
                    </div>
                    <hr/>

                </div>
       </div>
        <div className='col-12 d-flex justify-content-center'>
            <div className='p-3 my-2'
            style={{ 
                backgroundColor: 'whiteSmoke',
                borderRadius: '20px',
                // height: '150px',
                padding: 3
            }}
            >
                {/* <p className='small-header'> FOLLOW US ON FACEBOOK.</p> */}
                <div className='d-flex align-items-end'>
                    {/* <Facebook fontSize='large'/> */}
                    <FacebookRounded fontSize='large' />
                    {/* <a href='#'
                    style={{ 
                        color: 'navyBlue'
                    }}
                    >Click  to visit Kin Nupe Facebook page </a> */}
                </div>
            </div>
            <div className='p-3 my-2'
            style={{ 
                backgroundColor: 'whiteSmoke',
                borderRadius: '20px',
                // height: '150px',
                padding: 3
            }}
            >
                {/* <p className='small-header'> FOLLOW US ON INSTAGRAM.</p> */}
                <div className='d-flex align-items-end'>
                    <Instagram fontSize='large' />
                    {/* <a href='#'
                    style={{ 
                        color: 'navyBlue'
                    }}
                    >Click to visit Kin Nupe Instagram page </a> */}
                </div>
            </div>
            <div className='p-3 my-2'
            style={{ 
                backgroundColor: 'whiteSmoke',
                borderRadius: '20px',
                // height: '150px',
                padding: 3
            }}
            >
                {/* <p className='small-header'> FOLLOW US ON TWITTER(X).</p> */}
                <div className='d-flex align-items-end'>
                    <Twitter fontSize='large' />
                    {/* <a href='#'
                    style={{ 
                        color: 'navyBlue'
                    }}
                    >Click to visit Kin Nupe Twitter(X) page </a> */}
                </div>
            </div>
        </div>
    </div>
  )
}

export default Contact